export default api => {
  return {
    get: () => {
      return api.get('notifications' )
        .then(({ data: { items } }) => {
          return items
        })
        .catch(error => Promise.reject(error))
    }
  }
}
