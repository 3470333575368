export default api => {
  return {
    statusesList: (params = {}) => {
      return api.get('status/list', params)
        .then(({ data }) => {
          return data
        })
    }
  }
}
