import Vue from 'vue'

import './rcc-icon'
import './rcc-table'

import RccBtn from './rcc-btn.vue'
import RccInput from './rcc-input.vue'
import RccPanel from './rcc-panel.vue'
import RccConfirmDialogPlugin from './rcc-confirm'
// import KsFinWaitPlugin from './ks-fin-wait'

Vue.component('rcc-btn', RccBtn)
Vue.component('rcc-input', RccInput)
Vue.component('rcc-panel', RccPanel)

Vue.use(RccConfirmDialogPlugin)
// Vue.use(RccWaitPlugin)
