<template>
  <button class="rcc-btn" @click="clickHandle">
    <span class="btn-label">
      <slot/>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    clickHandle () {
      if (!this.disabled) this.$emit('click')
    }
  }
}
</script>

<style lang="less">
.rcc-btn {
  font-weight: 500;
  min-width: 4rem;

  color: #ffffff;
  background: #3F51B5;
  border: 0 none;
  padding: 0.643rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;

  margin: 0;
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;

  &:enabled:hover {
    background: rgba(63, 81, 181, 0.92);
    color: #ffffff;
    border-color: transparent;
  }

  &:enabled:active {
    background: rgba(63, 81, 181, 0.68);
    color: #ffffff;
    border-color: transparent;    
  }

  // &:enabled:focus {
  //   background: rgba(63, 81, 181, 0.76);
  // }

  // &:focus {
  //   outline: 0 none;
  //   outline-offset: 0;
  //   box-shadow: none;
  // }  

  .btn-label {
    transition: all .2s;
    flex: 1 1 auto;  
  }
}
</style>
