import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VNavigationDrawer,{class:_vm.classes,attrs:{"value":_vm.isOpen,"app":"","flat":""}},[_c('div',{staticClass:"sidebar__logo"},[_vm._v(" "+_vm._s(_vm.logoText)+" ")]),_c('div',_vm._l((_vm.menu),function(group,index){return _c('div',{key:index},[(group.items && group.items.length > 0)?_c(VSubheader,{staticClass:"subheader"},[_vm._v(" "+_vm._s(group.group)+" ")]):_vm._e(),_vm._l((group.items),function(item,index){return _c(VListItem,{key:index,class:_vm.getItemClasses(item),attrs:{"disabled":item.isDisabled},on:{"click":function($event){return _vm.handleMenuItemClick(item.pageName)}}},[_c(VListItemIcon,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1)})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }