import Cookies from 'js-cookie'

export class Storage {
  getCookie(key, defaultReturn = null) {
    return Cookies.get(key) ? JSON.parse(Cookies.get(key)) : defaultReturn
  }

  setCookie(key, value, options) {
    const { expires } = options || 30

    Cookies.set(key, JSON.stringify(value), {
      expires,
      sameSite: 'Lax'
    })
  }

  removeCookie(key) {
    Cookies.remove(key)
  }

  getLS(index) {
    return JSON.parse(localStorage.getItem(index)) || null
  }

  setLS(index, value) {
    localStorage.setItem(index, value)
  }

  removeLS(index) {
    localStorage.removeItem(index)
  }
}

export default new Storage()
