import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import axios from 'axios'
import { setGlobalDateI18n } from 'fecha'
import '@/components'
import '@/layouts'
import '@/plugins'
import store from './store/index.js'
import vuetify from './plugins/vuetify'
import { VueMaskDirective } from 'v-mask'
import Vuelidate from 'vuelidate'
import './styles/global.scss'
import constants from './utils/constants'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import apiMethods from '@/services/api-methods'

window.events = new Vue()
window.snackbar = (message, type) => {
  window.events.$emit('snackbar', message, type)
}

window.isLoading = status => {
  window.events.$emit('isLoading', status)
}

const $axios = axios.create({
  baseURL: '/admin',
  timeout: 20 * 60 * 1000,
  headers: { 'Content-Type': 'application/json' }
})

$axios.interceptors.response.use(
  response => { return response },
  async error => {
    if (error.response.status === 401) {
      const req = error.config
      if (req.url.includes('/refresh')) {
        store.user.logout()
      } else {
        const token = await store.user.refresh()
        req.headers['Authorization'] = 'Bearer ' + token
        return $axios.request(req)
      }
    } else if (error.response.status === 400 && error.config.url.includes('/refresh')) {
      store.user.logout()
    } else {
      console.dir(error)
      const message = error.response.data.message || error.message
      if (!message) {
        window.snackbar('Неизвестная ошибка', 'error')
      } else {
        window.snackbar(message, 'error')
      }

      return Promise.reject(error)
    }
  }
)

const $api = {
  get: (path, payload, params) => {
    return $axios.get(path + (payload ? ('?' + new URLSearchParams(payload).toString()) : ''), params)
  },

  delete: path => {
    return $axios.delete(path)
  },

  post: (path, payload) => {
    return $axios.post(path, payload)
  },

  put: (path, payload) => {
    return $axios.put(path, payload)
  }
}

const $apiMethods = apiMethods($api)

store.axios = $axios
store.user.axios = $axios
Vue.prototype.$axios = $axios
Vue.prototype.$api = $api
Vue.prototype.$apiMethods = $apiMethods
Vue.prototype.$constants = constants

setGlobalDateI18n({
  dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
  monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
  monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']
  // amPm: ['am', 'pm'],
  // DoFn: function (D) {
  // return D + [ 'th', 'st', 'nd', 'rd' ][ D % 10 > 3 ? 0 : (D - D % 10 !== 10) * D % 10 ];
  // }
})

Vue.directive('mask', VueMaskDirective)

Vue.use(Vuelidate)
Vue.use(require('vue-moment'))
Vue.use(FloatingVue)

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  data: { store },
  vuetify,
  render: h => h(App)
}).$mount('#app')
