<template>
  <div class="rcc-notification">
    <div class="rcc-notification__message">{{ message }}</div>
    <div class="rcc-notification__close">
      <v-btn class="rcc-notification__close-button" small icon @click="$emit('close')">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RccNotification',

  props: {
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-notification {
  min-height: 40px;
  display: flex;
  padding: 5px;

  &__message {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__close {
    width: 25px;
    position: relative;

    i {
      color: $error !important;
    }
  }

  &__close-button {
    position: absolute;
    top: -4px;
    right: -4px;
  }
}
</style>