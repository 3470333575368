export default api => {
  return {
    list: () => {
      return api.get('/district')
        .then(({ data: { results } }) => {
          return results
        })
    },

    create: payload => {
      return api.post('/district', payload)
    },

    get: id => {
      return api.get(`/district/${id}`)
        .then(({ data }) => {
          return data
        })
    },

    update: (id, payload) => {
      return api.put(`/district/${id}`, payload)
    },

    delete: id => {
      return api.delete(`/district/${id}`)
    }
  }
}
