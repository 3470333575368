import Vue from 'vue'

import VueStash from './stash.js'
// import VueWS from './ws-rpc.js'

Vue.use(VueStash)

// const protocol = (window.location.protocol === 'http:') ? 'ws' : 'wss'
// let host = window.location.host
// if (host == 'localhost:8080') host = '127.0.0.1:5000'
// const url = protocol + '://' + host + '/admin/ws'
// const options = {
//   reconnectionDelay: 5000,
//   connectManually: true
// }
// Vue.use(VueWS, url, options)
