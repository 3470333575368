<template>
  <v-dialog
    v-model="isShow"
    persistent
    :max-width="maxWidth"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="handleConfirmButtonClick"
        >
          {{ confirmText }}
        </v-btn>

        <v-btn
          color="green darken-1"
          text
          @click="isShow = false"
        >
          {{ cancelText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RccDialog-Button',

  props: {
    maxWidth: {
      type: String,
      default: '500px'
    },

    icon: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    text: {
      type: String,
      default: ''
    },

    confirmText: {
      type: String,
      default: 'Согласен'
    },

    cancelText: {
      type: String,
      default: 'Отмена'
    }
  },

  data() {
    return {
      isShow: false
    }
  },

  methods: {
    handleConfirmButtonClick() {
      this.$emit('confirm')
      this.isShow = false
      this.$store.user.logout()
    }
  }
}
</script>
