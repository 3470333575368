<template>
  <span class="rcc-input" :class="{'icon-left': iconLeft, 'icon-right': iconRight}">
    <rcc-icon class="icon-left" :icon="iconLeft" />
    <input class="p-inputtext p-component" :type="type" :placeholder="placeholder" :value="mutableValue" @input="inputHandle">
    <rcc-icon class="icon-right" :icon="iconRight" @click="iconClickHandle" />
  </span>    
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number]
    },

    placeholder: {
      type: String
    },

    iconLeft: {
      type: String
    },

    iconRight: {
      type: String
    },

    type: {
      type: String,
      default: 'text'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    validate: {
      type: Function,
      default: ch => {
        return ch
      }
    }
  },

  data () {
    return {
      mutableValue: '',
      mutableError: false
    }
  },

  watch: {
    value: {
      handler: 'initValue',
      immediate: true
    },

    mutableValue: {
      handler: 'mutableValueChanged'
    }
  },

  methods: {
    initValue (val) {
      this.mutableValue = this.validate(val || '')
      this.mutableError = false
    },

    inputHandle (e) {
      // e.stopPropagation()
      const val = this.validate(e.target.value)
      e.target.value = val
      this.mutableValue = val
    },

    mutableValueChanged (val) {
      if (val != this.value){
        this.$emit('input', val)
      }
    },

    iconClickHandle () {
      // if (this.clearable) {
      //   this.mutableValue = ''
      // }
      this.$emit('icon-click')
    }
  }
}
</script>

<style lang="less">
.rcc-input {
  display: block;
  // display: inline-block;
  width: 100%;
  position: relative;

  .icon-left {
    left: 5px;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    z-index: 100;
  }

  .icon-right {
    right: 5px;
    position: absolute;
    top: 50%;
    margin-top: -11px;
    z-index: 100;
    cursor: pointer;
  }

  input {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    background: #ffffff;
    padding: 12px 10px 8px;

    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    appearance: none;
    border-radius: 4px;

    width: 100%;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid transparent;
    background: #f5f5f5 no-repeat;
    background-image: linear-gradient(to bottom, #3F51B5, #3F51B5), linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
    background-size: 0 2px, 100% 1px;
    background-position: 50% 100%, 50% 100%;
    background-origin: border-box;
    margin: 0;
    
    &:enabled:hover {
      background-color: #ececec;
      border-color: transparent;
      background-image: linear-gradient(to bottom, #3F51B5, #3F51B5), linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
    }

    &:enabled:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: none;
      border-color: #3F51B5;

      box-shadow: none;
      background-color: #dcdcdc;
      border-color: transparent;
      background-size: 100% 2px, 100% 1px;
    }    
  }
}

.rcc-input.icon-left input {
  padding-left: 30px;
}

.rcc-input.icon-right input {
  padding-right: 30px;
}
</style>
