<template>
  <div
    class="rcc-dialog"
    @click.self="cancelKey"
    @keyup.esc="cancelKey"
    @keyup.enter="confirmKey"
    tabindex="-1"
    v-if="showed">
    <rcc-panel :head="text">
      <slot />

      <div class="rcc-dialog-tools" v-if="dialogMode">
        <rcc-btn type="transparent" @click="cancel">{{cancelText}}</rcc-btn>
        <rcc-btn ref="defBtn" type="transparent" @click="confirm">{{confirmText}}</rcc-btn>
      </div>
      <div class="rcc-dialog-tools" v-else>
        <rcc-btn ref="defBtn" type="transparent" @click="confirm">OK</rcc-btn>
      </div>
    </rcc-panel>    
  </div>
</template>

<script>
const RCC_CLS = 'rcc-toast-container'

export default {
  props: {
    text: {
      type: String
    },

    cancelText: {
      type: String,
      default: 'Нет'
    },

    confirmText: {
      type: String,
      default: 'Да'
    },

    auto: {
      // авто-управление видимостью
      type: Boolean,
      default: false
    },

    noKey: {
      // не закрывать при клике мимо или esc или enter
      type: Boolean,
      default: false
    },

    show: {
      type: Boolean,
      default: false
    },

    resolve: {
      type: Function
    },

    reject: {
      type: Function
    }
  },

  data () {
    return {
      showed: false
    }
  },

  watch: {
    show: {
      handler: function (val) {
        if (!this.auto) {
          this.showed = val
        }
      },
      immediate: true
    },

    showed (val) {
      if (val) {
        // this.$nextTick(() => { this.$refs.defBtn.$el.focus() })
      }
    }
  },

  beforeMount () {
    if (this.$el) {
      let container = document.querySelector('.' + RCC_CLS)
      if (!container) {
        container = document.createElement('div')
        container.classList.add(RCC_CLS)
        document.body.appendChild(container)
      }
      container.appendChild(this.$el)
    }
  },

  mounted () {
    if (this.auto) {
      this.showed = true
    }
  },

  computed: {
    dialogMode () {
      return this.reject || this.$listeners.confirm
    }
  },

  methods: {
    cancelKey () {
      if (!this.noKey) this.cancel()
    },

    confirmKey () {
      if (!this.noKey) this.confirm()
    },

    confirm () {
      if (this.auto) {
        this.showed = false
      }
      this.$emit('confirm')
      if (this.resolve) {
        this.resolve('confirm')
      }
    },

    cancel () {
      if (this.auto) {
        this.showed = false
      }

      if (this.dialogMode) {
        this.$emit('cancel')
        if (this.reject) {
          this.reject('cancel')
        }
      } else {
        this.$emit('confirm')
        if (this.confirm) {
          this.confirm()
        }
      }
    }
  }
}
</script>

<style lang="less">
@import "../../style.less";

.rcc-dialog {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;

  // .rcc-dialog-wrap {
  //   text-align: center;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   margin-right: -50%;
  //   transform: translate(-50%, -50%);
  //   background: @gray0-color;
  //   border-radius: 4px;
  //   padding: 20px 57px 27px;

  //   .rcc-dialog-tools {
  //     display: flex;
  //     margin: 27px auto 0;
  //     max-width: 600px;
  //     min-width: 300px;
  //     justify-content: center;
  //     flex-direction: row;
  //     align-items: flex-end;
  //   }

  //   .rcc-btn {
  //     width: 178px;
  //     margin: 12px;
  //   }
  // }
}
</style>
