export function getRegionsList(params) {
  return this.$api.get('region/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getRegions(params) {
      return this.$api.get('regions', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getRegion(id) {
      return this.$api.get('region/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createRegion(params) {
      return this.$api.post('region/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateRegion(id, params) {
      return this.$api.post('region/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}

export function regions(api) {
  return {
    allList: params => {
      return api.get('region/list', params)
        .then(({ data }) => data)
    },

    get: id => {
      return api.get('region/' + id)
        .then(({ data }) => data)
    }
  }
}
