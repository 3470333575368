<template>
  <v-overlay
      :value="isShow"
      z-index="100"
      class="align-center justify-center overlay"
    >
      <v-progress-circular
        color="white"
        size="80"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
export default {
  name: 'RccLoadingOverlay',

  data() {
    return {
      isShow: false
    }
  },

  created() {
    window.events.$on('isLoading', this.changeOverlayStatus)
  },

  methods: {
    changeOverlayStatus(status) {
      this.isShow = status
    }
  }
}
</script>
