<template>
  <div class="rcc-table">
    TABLE
    <!-- <div class="rcc-table rcc-table-scrollable rcc-table-scrollable-both rcc-table-responsive-stack">
      ййй
      <div class="rcc-table-wrapper" style="max-height: 400px;">
        <table>
          <thead>
            <tr>
              <th style="width: 150px; left: 0px;" class="p-frozen-column">
                <div class="p-column-header-content">
                  <span class="p-column-title">Name</span>
                </div>
              </th>
              <th style="width: 100px; left: 150px;" class="p-frozen-column">
                <div class="p-column-header-content">
                  <span class="p-column-title">Id</span>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="p-column-header-content">
                  <span class="p-column-title">Name</span>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="p-column-header-content">
                  <span class="p-column-title">Country</span>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="p-column-header-content">
                  <span class="p-column-title">Date</span>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="p-column-header-content">
                  <span class="p-column-title">Company</span>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="p-column-header-content">
                  <span class="p-column-title">Status</span>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="p-column-header-content">
                  <span class="p-column-title">Activity</span>
                </div>
              </th>
              <th style="width: 200px;">
                <div class="p-column-header-content">
                  <span class="p-column-title">Representative</span>
                </div>
              </th>
              <th style="width: 150px; right: 0px;" class="p-frozen-column">
                <div class="p-column-header-content">
                  <span class="p-column-title">Balance</span>
                </div>
              </th>
            </tr>
          </thead>
          
          <tbody>
            <tr>
              <td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>James Butt</td>
              <td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1000</td>
              <td style="width: 200px;"><span class="p-column-title">Name</span>James Butt</td>
              <td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-dz" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Algeria</span></td>
              <td style="width: 200px;"><span class="p-column-title">Date</span>2015-09-13</td>
              <td style="width: 200px;"><span class="p-column-title">Company</span>Benton, John B Jr</td>
              <td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td>
              <td style="width: 200px;"><span class="p-column-title">Activity</span>17</td>
              <td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td>
              <td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$70,663.00</span></td>
            </tr>
            
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Josephine Darakjy</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1001</td><td style="width: 200px;"><span class="p-column-title">Name</span>Josephine Darakjy</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-eg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Egypt</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-02-09</td><td style="width: 200px;"><span class="p-column-title">Company</span>Chanay, Jeffrey A Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>0</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$82,429.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Art Venere</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1002</td><td style="width: 200px;"><span class="p-column-title">Name</span>Art Venere</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Panama</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-05-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Chemel, James L Cpa</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>63</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$28,334.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lenna Paprocki</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1003</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lenna Paprocki</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-si" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Slovenia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-09-15</td><td style="width: 200px;"><span class="p-column-title">Company</span>Feltz Printing Service</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>37</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$88,521.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Donette Foller</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1004</td><td style="width: 200px;"><span class="p-column-title">Name</span>Donette Foller</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-za" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">South Africa</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-05-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>Printing Dimensions</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>33</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$93,905.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Simona Morasca</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1005</td><td style="width: 200px;"><span class="p-column-title">Name</span>Simona Morasca</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-eg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Egypt</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-02-16</td><td style="width: 200px;"><span class="p-column-title">Company</span>Chapman, Ross E Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>68</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$50,041.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Mitsue Tollner</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1006</td><td style="width: 200px;"><span class="p-column-title">Name</span>Mitsue Tollner</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-py" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Paraguay</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-02-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Morlong Associates</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>54</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$58,706.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Leota Dilliard</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1007</td><td style="width: 200px;"><span class="p-column-title">Name</span>Leota Dilliard</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-rs" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Serbia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-08-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Commercial Press</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>69</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$26,640.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Sage Wieser</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1008</td><td style="width: 200px;"><span class="p-column-title">Name</span>Sage Wieser</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-eg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Egypt</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-11-21</td><td style="width: 200px;"><span class="p-column-title">Company</span>Truhlar And Truhlar Attys</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>76</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$65,369.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Kris Marrier</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1009</td><td style="width: 200px;"><span class="p-column-title">Name</span>Kris Marrier</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-mx" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Mexico</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-07-07</td><td style="width: 200px;"><span class="p-column-title">Company</span>King, Christopher A Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>3</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$63,451.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Minna Amigon</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1010</td><td style="width: 200px;"><span class="p-column-title">Name</span>Minna Amigon</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ro" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Romania</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-11-07</td><td style="width: 200px;"><span class="p-column-title">Company</span>Dorl, James J Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>38</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$71,169.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Abel Maclead</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1011</td><td style="width: 200px;"><span class="p-column-title">Name</span>Abel Maclead</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Singapore</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Rangoni Of Florence</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>87</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$96,842.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Kiley Caldarera</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1012</td><td style="width: 200px;"><span class="p-column-title">Name</span>Kiley Caldarera</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-rs" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Serbia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-10-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>Feiner Bros</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>80</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$92,734.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Graciela Ruta</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1013</td><td style="width: 200px;"><span class="p-column-title">Name</span>Graciela Ruta</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Chile</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-07-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Buckley Miller &amp; Wright</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>59</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$45,250.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Cammy Albares</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1014</td><td style="width: 200px;"><span class="p-column-title">Name</span>Cammy Albares</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ph" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Philippines</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-06-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Rousseaux, Michael Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>90</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$30,236.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Mattie Poquette</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1015</td><td style="width: 200px;"><span class="p-column-title">Name</span>Mattie Poquette</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ve" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Venezuela</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-12-12</td><td style="width: 200px;"><span class="p-column-title">Company</span>Century Communications</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>52</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$64,533.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Meaghan Garufi</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1016</td><td style="width: 200px;"><span class="p-column-title">Name</span>Meaghan Garufi</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-my" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Malaysia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-07-04</td><td style="width: 200px;"><span class="p-column-title">Company</span>Bolton, Wilbur Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>31</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$37,279.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Gladys Rim</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1017</td><td style="width: 200px;"><span class="p-column-title">Name</span>Gladys Rim</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-02-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>T M Byxbee Company Pc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>48</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$27,381.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Yuki Whobrey</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1018</td><td style="width: 200px;"><span class="p-column-title">Name</span>Yuki Whobrey</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-il" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Israel</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-12-21</td><td style="width: 200px;"><span class="p-column-title">Company</span>Farmers Insurance Group</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>16</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$9,257.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Fletcher Flosi</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1019</td><td style="width: 200px;"><span class="p-column-title">Name</span>Fletcher Flosi</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ar" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Argentina</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-01-04</td><td style="width: 200px;"><span class="p-column-title">Company</span>Post Box Services Plus</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>19</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$67,783.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Bette Nicka</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1020</td><td style="width: 200px;"><span class="p-column-title">Name</span>Bette Nicka</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-py" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Paraguay</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-10-21</td><td style="width: 200px;"><span class="p-column-title">Company</span>Sport En Art</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>100</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$4,609.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Veronika Inouye</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1021</td><td style="width: 200px;"><span class="p-column-title">Name</span>Veronika Inouye</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ec" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ecuador</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-24</td><td style="width: 200px;"><span class="p-column-title">Company</span>C 4 Network Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>72</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$26,565.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Willard Kolmetz</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1022</td><td style="width: 200px;"><span class="p-column-title">Name</span>Willard Kolmetz</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-tn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Tunisia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-04-15</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ingalls, Donald R Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>94</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$75,876.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Maryann Royster</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1023</td><td style="width: 200px;"><span class="p-column-title">Name</span>Maryann Royster</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-by" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belarus</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Franklin, Peter L Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>56</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$41,121.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Alisha Slusarski</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1024</td><td style="width: 200px;"><span class="p-column-title">Name</span>Alisha Slusarski</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-is" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Iceland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-03-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Wtlz Power 107 Fm</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>7</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$91,691.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Allene Iturbide</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1025</td><td style="width: 200px;"><span class="p-column-title">Name</span>Allene Iturbide</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-it" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Italy</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ledecky, David Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>1</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$40,137.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Chanel Caudy</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1026</td><td style="width: 200px;"><span class="p-column-title">Name</span>Chanel Caudy</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ar" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Argentina</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-06-24</td><td style="width: 200px;"><span class="p-column-title">Company</span>Professional Image Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>26</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$21,304.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Ezekiel Chui</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1027</td><td style="width: 200px;"><span class="p-column-title">Name</span>Ezekiel Chui</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ie" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ireland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-09-24</td><td style="width: 200px;"><span class="p-column-title">Company</span>Sider, Donald C Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>76</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$60,454.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Willow Kusko</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1028</td><td style="width: 200px;"><span class="p-column-title">Name</span>Willow Kusko</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ro" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Romania</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-04-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>U Pull It</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>7</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$17,565.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Bernardo Figeroa</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1029</td><td style="width: 200px;"><span class="p-column-title">Name</span>Bernardo Figeroa</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-il" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Israel</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Clark, Richard Cpa</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>81</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$17,774.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Ammie Corrio</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1030</td><td style="width: 200px;"><span class="p-column-title">Name</span>Ammie Corrio</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-hu" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Hungary</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-06-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Moskowitz, Barry S</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>56</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$49,201.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Francine Vocelka</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1031</td><td style="width: 200px;"><span class="p-column-title">Name</span>Francine Vocelka</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-hn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Honduras</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-08-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Cascade Realty Advisors Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>94</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$67,126.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Ernie Stenseth</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1032</td><td style="width: 200px;"><span class="p-column-title">Name</span>Ernie Stenseth</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-au" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Australia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-06-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Knwz Newsradio</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>68</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$76,017.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Albina Glick</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1033</td><td style="width: 200px;"><span class="p-column-title">Name</span>Albina Glick</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ua" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ukraine</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-08-08</td><td style="width: 200px;"><span class="p-column-title">Company</span>Giampetro, Anthony D</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>85</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$91,201.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Alishia Sergi</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1034</td><td style="width: 200px;"><span class="p-column-title">Name</span>Alishia Sergi</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-qa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Qatar</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-05-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Milford Enterprises Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>46</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$12,237.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Solange Shinko</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1035</td><td style="width: 200px;"><span class="p-column-title">Name</span>Solange Shinko</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cm" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Cameroon</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-02-12</td><td style="width: 200px;"><span class="p-column-title">Company</span>Mosocco, Ronald A</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>32</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$34,072.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Jose Stockham</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1036</td><td style="width: 200px;"><span class="p-column-title">Name</span>Jose Stockham</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-it" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Italy</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Tri State Refueler Co</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>77</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$94,909.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Rozella Ostrosky</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1037</td><td style="width: 200px;"><span class="p-column-title">Name</span>Rozella Ostrosky</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ve" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Venezuela</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Parkway Company</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>66</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$57,245.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Valentine Gillian</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1038</td><td style="width: 200px;"><span class="p-column-title">Name</span>Valentine Gillian</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-py" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Paraguay</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-09-17</td><td style="width: 200px;"><span class="p-column-title">Company</span>Fbs Business Finance</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>25</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$75,502.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Kati Rulapaugh</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1039</td><td style="width: 200px;"><span class="p-column-title">Name</span>Kati Rulapaugh</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Puerto Rico</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-12-03</td><td style="width: 200px;"><span class="p-column-title">Company</span>Eder Assocs Consltng Engrs Pc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>51</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$82,075.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Youlanda Schemmer</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1040</td><td style="width: 200px;"><span class="p-column-title">Name</span>Youlanda Schemmer</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-bo" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bolivia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-12-15</td><td style="width: 200px;"><span class="p-column-title">Company</span>Tri M Tool Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>49</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$19,208.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Dyan Oldroyd</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1041</td><td style="width: 200px;"><span class="p-column-title">Name</span>Dyan Oldroyd</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ar" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Argentina</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-02-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>International Eyelets Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>5</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$50,194.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Roxane Campain</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1042</td><td style="width: 200px;"><span class="p-column-title">Name</span>Roxane Campain</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">France</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-12-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Rapid Trading Intl</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>100</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$77,714.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lavera Perin</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1043</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lavera Perin</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-vn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Vietnam</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Abc Enterprises Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>71</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$35,740.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Erick Ferencz</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1044</td><td style="width: 200px;"><span class="p-column-title">Name</span>Erick Ferencz</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-be" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belgium</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-05-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Cindy Turner Associates</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>54</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$30,790.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Fatima Saylors</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1045</td><td style="width: 200px;"><span class="p-column-title">Name</span>Fatima Saylors</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ca" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Canada</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-07-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Stanton, James D Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>93</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$52,343.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Jina Briddick</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1046</td><td style="width: 200px;"><span class="p-column-title">Name</span>Jina Briddick</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-mx" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Mexico</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-02-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Grace Pastries Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>97</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$53,966.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Kanisha Waycott</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1047</td><td style="width: 200px;"><span class="p-column-title">Name</span>Kanisha Waycott</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ec" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ecuador</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-11-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Schroer, Gene E Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>80</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$9,920.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Emerson Bowley</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1048</td><td style="width: 200px;"><span class="p-column-title">Name</span>Emerson Bowley</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fi" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Finland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-11-24</td><td style="width: 200px;"><span class="p-column-title">Company</span>Knights Inn</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>63</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$78,069.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Blair Malet</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1049</td><td style="width: 200px;"><span class="p-column-title">Name</span>Blair Malet</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fi" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Finland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Bollinger Mach Shp &amp; Shipyard</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>92</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$65,005.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Brock Bolognia</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1050</td><td style="width: 200px;"><span class="p-column-title">Name</span>Brock Bolognia</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-bo" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bolivia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-09-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Orinda News</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>72</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$51,038.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lorrie Nestle</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1051</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lorrie Nestle</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-de" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Germany</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-26</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ballard Spahr Andrews</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>36</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$28,218.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Sabra Uyetake</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1052</td><td style="width: 200px;"><span class="p-column-title">Name</span>Sabra Uyetake</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pe" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Peru</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-12</td><td style="width: 200px;"><span class="p-column-title">Company</span>Lowy Limousine Service</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>31</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$78,527.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Marjory Mastella</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1053</td><td style="width: 200px;"><span class="p-column-title">Name</span>Marjory Mastella</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-01-24</td><td style="width: 200px;"><span class="p-column-title">Company</span>Vicon Corporation</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>89</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$23,381.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Karl Klonowski</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1054</td><td style="width: 200px;"><span class="p-column-title">Name</span>Karl Klonowski</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Saudi Arabia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-04-17</td><td style="width: 200px;"><span class="p-column-title">Company</span>Rossi, Michael M</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>27</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$64,821.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Tonette Wenner</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1055</td><td style="width: 200px;"><span class="p-column-title">Name</span>Tonette Wenner</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-au" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Australia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-04-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Northwest Publishing</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>27</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$55,334.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Amber Monarrez</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1056</td><td style="width: 200px;"><span class="p-column-title">Name</span>Amber Monarrez</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-se" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Sweden</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-09-09</td><td style="width: 200px;"><span class="p-column-title">Company</span>Branford Wire &amp; Mfg Co</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>79</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$83,391.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Shenika Seewald</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1057</td><td style="width: 200px;"><span class="p-column-title">Name</span>Shenika Seewald</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-au" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Australia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-02-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>East Coast Marketing</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>39</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$31,580.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Delmy Ahle</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1058</td><td style="width: 200px;"><span class="p-column-title">Name</span>Delmy Ahle</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-be" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belgium</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-10-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Wye Technologies Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>55</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$11,723.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Deeanna Juhas</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1059</td><td style="width: 200px;"><span class="p-column-title">Name</span>Deeanna Juhas</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-se" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Sweden</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-09-28</td><td style="width: 200px;"><span class="p-column-title">Company</span>Healy, George W Iv</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>79</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$8,454.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Blondell Pugh</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1060</td><td style="width: 200px;"><span class="p-column-title">Name</span>Blondell Pugh</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ie" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ireland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-06-16</td><td style="width: 200px;"><span class="p-column-title">Company</span>Alpenlite Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>49</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$99,235.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Jamal Vanausdal</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1061</td><td style="width: 200px;"><span class="p-column-title">Name</span>Jamal Vanausdal</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ma" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Morocco</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-05-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Hubbard, Bruce Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>87</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$15,656.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Cecily Hollack</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1062</td><td style="width: 200px;"><span class="p-column-title">Name</span>Cecily Hollack</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-bo" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bolivia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-05-09</td><td style="width: 200px;"><span class="p-column-title">Company</span>Arthur A Oliver &amp; Son Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>5</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$60,586.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Carmelina Lindall</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1063</td><td style="width: 200px;"><span class="p-column-title">Name</span>Carmelina Lindall</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Puerto Rico</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-09-07</td><td style="width: 200px;"><span class="p-column-title">Company</span>George Jessop Carter Jewelers</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>77</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$86,239.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Maurine Yglesias</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1064</td><td style="width: 200px;"><span class="p-column-title">Name</span>Maurine Yglesias</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-tw" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Taiwan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-08-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Schultz, Thomas C Md</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>94</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$15,621.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Tawna Buvens</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1065</td><td style="width: 200px;"><span class="p-column-title">Name</span>Tawna Buvens</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-id" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Indonesia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-03-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>H H H Enterprises Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>25</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$77,248.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Penney Weight</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1066</td><td style="width: 200px;"><span class="p-column-title">Name</span>Penney Weight</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-za" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">South Africa</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-03-03</td><td style="width: 200px;"><span class="p-column-title">Company</span>Hawaiian King Hotel</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>96</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$478.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Elly Morocco</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1067</td><td style="width: 200px;"><span class="p-column-title">Name</span>Elly Morocco</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-th" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Thailand</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-09-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Killion Industries</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>38</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$62,505.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Ilene Eroman</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1068</td><td style="width: 200px;"><span class="p-column-title">Name</span>Ilene Eroman</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-06-08</td><td style="width: 200px;"><span class="p-column-title">Company</span>Robinson, William J Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>49</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$91,480.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Vallie Mondella</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1069</td><td style="width: 200px;"><span class="p-column-title">Name</span>Vallie Mondella</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-lv" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Latvia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-12-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Private Properties</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>16</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$21,671.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Kallie Blackwood</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1070</td><td style="width: 200px;"><span class="p-column-title">Name</span>Kallie Blackwood</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-is" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Iceland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-04-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Rowley Schlimgen Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>25</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$13,775.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Johnetta Abdallah</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1071</td><td style="width: 200px;"><span class="p-column-title">Name</span>Johnetta Abdallah</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-02-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Forging Specialties</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>16</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$60,253.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Bobbye Rhym</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1072</td><td style="width: 200px;"><span class="p-column-title">Name</span>Bobbye Rhym</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ua" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ukraine</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-08-17</td><td style="width: 200px;"><span class="p-column-title">Company</span>Smits, Patricia Garity</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>85</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$75,225.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Micaela Rhymes</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1073</td><td style="width: 200px;"><span class="p-column-title">Name</span>Micaela Rhymes</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">France</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-09-08</td><td style="width: 200px;"><span class="p-column-title">Company</span>H Lee Leonard Attorney At Law</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>92</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$3,308.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Tamar Hoogland</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1074</td><td style="width: 200px;"><span class="p-column-title">Name</span>Tamar Hoogland</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gt" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Guatemala</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-11-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>A K Construction Co</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>22</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$19,711.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Moon Parlato</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1075</td><td style="width: 200px;"><span class="p-column-title">Name</span>Moon Parlato</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cz" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Czech Republic</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-08-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ambelang, Jessica M Md</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>64</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$55,110.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Laurel Reitler</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1076</td><td style="width: 200px;"><span class="p-column-title">Name</span>Laurel Reitler</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gb" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">United Kingdom</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-04-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Q A Service</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>80</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$62,392.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Delisa Crupi</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1077</td><td style="width: 200px;"><span class="p-column-title">Name</span>Delisa Crupi</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-tw" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Taiwan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-09-15</td><td style="width: 200px;"><span class="p-column-title">Company</span>Wood &amp; Whitacre Contractors</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>70</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$76,530.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Viva Toelkes</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1078</td><td style="width: 200px;"><span class="p-column-title">Name</span>Viva Toelkes</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-us" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">United States</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Mark Iv Press Ltd</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>16</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$7,460.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Elza Lipke</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1079</td><td style="width: 200px;"><span class="p-column-title">Name</span>Elza Lipke</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ie" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ireland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-06-01</td><td style="width: 200px;"><span class="p-column-title">Company</span>Museum Of Science &amp; Industry</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>90</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$42,251.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Devorah Chickering</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1080</td><td style="width: 200px;"><span class="p-column-title">Name</span>Devorah Chickering</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-es" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Spain</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Garrison Ind</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>96</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$36,435.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Timothy Mulqueen</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1081</td><td style="width: 200px;"><span class="p-column-title">Name</span>Timothy Mulqueen</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-07-09</td><td style="width: 200px;"><span class="p-column-title">Company</span>Saronix Nymph Products</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>77</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$39,197.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Arlette Honeywell</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1082</td><td style="width: 200px;"><span class="p-column-title">Name</span>Arlette Honeywell</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Panama</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-09-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Smc Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>46</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$72,707.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Dominque Dickerson</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1083</td><td style="width: 200px;"><span class="p-column-title">Name</span>Dominque Dickerson</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ar" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Argentina</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-11-12</td><td style="width: 200px;"><span class="p-column-title">Company</span>E A I Electronic Assocs Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>83</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$97,965.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lettie Isenhower</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1084</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lettie Isenhower</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ca" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Canada</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-03-01</td><td style="width: 200px;"><span class="p-column-title">Company</span>Conte, Christopher A Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>83</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$5,823.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Myra Munns</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1085</td><td style="width: 200px;"><span class="p-column-title">Name</span>Myra Munns</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-lt" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Lithuania</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-05-21</td><td style="width: 200px;"><span class="p-column-title">Company</span>Anker Law Office</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>49</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$96,471.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Stephaine Barfield</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1086</td><td style="width: 200px;"><span class="p-column-title">Name</span>Stephaine Barfield</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-be" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belgium</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-01-22</td><td style="width: 200px;"><span class="p-column-title">Company</span>Beutelschies &amp; Company</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>34</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$33,710.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lai Gato</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1087</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lai Gato</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ng" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Nigeria</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-07-26</td><td style="width: 200px;"><span class="p-column-title">Company</span>Fligg, Kenneth I Jr</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>64</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$30,611.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Stephen Emigh</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1088</td><td style="width: 200px;"><span class="p-column-title">Name</span>Stephen Emigh</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cu" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Cuba</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-07-24</td><td style="width: 200px;"><span class="p-column-title">Company</span>Sharp, J Daniel Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>51</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$32,960.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Tyra Shields</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1089</td><td style="width: 200px;"><span class="p-column-title">Name</span>Tyra Shields</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-hn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Honduras</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-11-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Assink, Anne H Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>11</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$57,423.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Tammara Wardrip</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1090</td><td style="width: 200px;"><span class="p-column-title">Name</span>Tammara Wardrip</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Saudi Arabia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-06-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Jewel My Shop Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>64</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$23,027.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Cory Gibes</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1091</td><td style="width: 200px;"><span class="p-column-title">Name</span>Cory Gibes</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-my" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Malaysia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-28</td><td style="width: 200px;"><span class="p-column-title">Company</span>Chinese Translation Resources</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>44</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$84,182.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Danica Bruschke</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1092</td><td style="width: 200px;"><span class="p-column-title">Name</span>Danica Bruschke</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-tw" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Taiwan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-12-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Stevens, Charles T</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>62</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$25,237.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Wilda Giguere</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1093</td><td style="width: 200px;"><span class="p-column-title">Name</span>Wilda Giguere</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-is" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Iceland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-06-16</td><td style="width: 200px;"><span class="p-column-title">Company</span>Mclaughlin, Luther W Cpa</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>79</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$87,736.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Elvera Benimadho</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1094</td><td style="width: 200px;"><span class="p-column-title">Name</span>Elvera Benimadho</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-my" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Malaysia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-02-17</td><td style="width: 200px;"><span class="p-column-title">Company</span>Tree Musketeers</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>50</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$38,674.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Carma Vanheusen</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1095</td><td style="width: 200px;"><span class="p-column-title">Name</span>Carma Vanheusen</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-tr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Turkey</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-11-26</td><td style="width: 200px;"><span class="p-column-title">Company</span>Springfield Div Oh Edison Co</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>84</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$67,762.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Malinda Hochard</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1096</td><td style="width: 200px;"><span class="p-column-title">Name</span>Malinda Hochard</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-rs" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Serbia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-07-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Logan Memorial Hospital</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>88</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$81,299.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Natalie Fern</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1097</td><td style="width: 200px;"><span class="p-column-title">Name</span>Natalie Fern</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ca" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Canada</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-10-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Kelly, Charles G Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>44</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$64,794.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lisha Centini</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1098</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lisha Centini</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-07-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Industrial Paper Shredders Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>7</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$7,815.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Arlene Klusman</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1099</td><td style="width: 200px;"><span class="p-column-title">Name</span>Arlene Klusman</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-jm" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Jamaica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-05-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Beck Horizon Builders</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>99</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$37,976.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Alease Buemi</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1100</td><td style="width: 200px;"><span class="p-column-title">Name</span>Alease Buemi</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Costa Rica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-03-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Porto Cayo At Hawks Cay</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>0</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$59,594.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Louisa Cronauer</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1101</td><td style="width: 200px;"><span class="p-column-title">Name</span>Louisa Cronauer</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Costa Rica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-09-23</td><td style="width: 200px;"><span class="p-column-title">Company</span>Pacific Grove Museum Ntrl Hist</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>3</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$92,528.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Angella Cetta</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1102</td><td style="width: 200px;"><span class="p-column-title">Name</span>Angella Cetta</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-vn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Vietnam</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Bender &amp; Hatley Pc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>88</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$58,964.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Cyndy Goldammer</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1103</td><td style="width: 200px;"><span class="p-column-title">Name</span>Cyndy Goldammer</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-bf" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Burkina Faso</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-09-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Di Cristina J &amp; Son</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>92</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$65,860.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Rosio Cork</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1104</td><td style="width: 200px;"><span class="p-column-title">Name</span>Rosio Cork</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Singapore</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-08-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Green Goddess</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>19</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$63,863.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Celeste Korando</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1105</td><td style="width: 200px;"><span class="p-column-title">Name</span>Celeste Korando</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Costa Rica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-06-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>American Arts &amp; Graphics</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>21</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$37,510.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Twana Felger</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1106</td><td style="width: 200px;"><span class="p-column-title">Name</span>Twana Felger</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-hr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Croatia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-11-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Opryland Hotel</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>97</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$63,876.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Estrella Samu</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1107</td><td style="width: 200px;"><span class="p-column-title">Name</span>Estrella Samu</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-vn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Vietnam</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-06-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Marking Devices Pubg Co</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>27</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$93,263.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Donte Kines</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1108</td><td style="width: 200px;"><span class="p-column-title">Name</span>Donte Kines</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sk" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Slovakia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-02-16</td><td style="width: 200px;"><span class="p-column-title">Company</span>W Tc Industries Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>35</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$57,198.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Tiffiny Steffensmeier</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1109</td><td style="width: 200px;"><span class="p-column-title">Name</span>Tiffiny Steffensmeier</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pk" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Pakistan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-03-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Whitehall Robbins Labs Divsn</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>81</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$89,147.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Edna Miceli</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1110</td><td style="width: 200px;"><span class="p-column-title">Name</span>Edna Miceli</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">France</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-10-15</td><td style="width: 200px;"><span class="p-column-title">Company</span>Sampler</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>54</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$41,466.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Sue Kownacki</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1111</td><td style="width: 200px;"><span class="p-column-title">Name</span>Sue Kownacki</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-jm" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Jamaica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-17</td><td style="width: 200px;"><span class="p-column-title">Company</span>Juno Chefs Incorporated</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>31</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$38,918.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Jesusa Shin</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1112</td><td style="width: 200px;"><span class="p-column-title">Name</span>Jesusa Shin</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ua" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ukraine</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-04-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Carroccio, A Thomas Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>28</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$11,397.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Rolland Francescon</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1113</td><td style="width: 200px;"><span class="p-column-title">Name</span>Rolland Francescon</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gb" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">United Kingdom</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-02-03</td><td style="width: 200px;"><span class="p-column-title">Company</span>Stanley, Richard L Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>45</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$40,930.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Pamella Schmierer</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1114</td><td style="width: 200px;"><span class="p-column-title">Name</span>Pamella Schmierer</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-be" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belgium</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-09-22</td><td style="width: 200px;"><span class="p-column-title">Company</span>K Cs Cstm Mouldings Windows</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>34</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$40,847.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Glory Kulzer</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1115</td><td style="width: 200px;"><span class="p-column-title">Name</span>Glory Kulzer</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-hr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Croatia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-09-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Comfort Inn</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>36</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$27,832.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Shawna Palaspas</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1116</td><td style="width: 200px;"><span class="p-column-title">Name</span>Shawna Palaspas</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ee" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Estonia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-06-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Windsor, James L Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>69</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$89,060.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Brandon Callaro</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1117</td><td style="width: 200px;"><span class="p-column-title">Name</span>Brandon Callaro</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ro" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Romania</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-07-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Jackson Shields Yeiser</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>55</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$52,474.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Scarlet Cartan</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1118</td><td style="width: 200px;"><span class="p-column-title">Name</span>Scarlet Cartan</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Panama</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-09-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Box, J Calvin Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>1</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$19,094.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Oretha Menter</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1119</td><td style="width: 200px;"><span class="p-column-title">Name</span>Oretha Menter</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Panama</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-09-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Custom Engineering Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>8</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$93,756.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Ty Smith</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1120</td><td style="width: 200px;"><span class="p-column-title">Name</span>Ty Smith</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-us" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">United States</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-07-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Bresler Eitel Framg Gllry Ltd</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>50</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$77,388.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Xuan Rochin</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1121</td><td style="width: 200px;"><span class="p-column-title">Name</span>Xuan Rochin</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-co" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Colombia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-05-22</td><td style="width: 200px;"><span class="p-column-title">Company</span>Carol, Drake Sparks Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>77</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$48,759.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lindsey Dilello</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1122</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lindsey Dilello</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-at" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Austria</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-07-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Biltmore Investors Bank</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>65</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$37,568.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Devora Perez</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1123</td><td style="width: 200px;"><span class="p-column-title">Name</span>Devora Perez</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-uy" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Uruguay</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-10-09</td><td style="width: 200px;"><span class="p-column-title">Company</span>Desco Equipment Corp</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>30</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$4,477.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Herman Demesa</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1124</td><td style="width: 200px;"><span class="p-column-title">Name</span>Herman Demesa</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-py" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Paraguay</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-05-23</td><td style="width: 200px;"><span class="p-column-title">Company</span>Merlin Electric Co</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>10</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$13,764.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Rory Papasergi</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1125</td><td style="width: 200px;"><span class="p-column-title">Name</span>Rory Papasergi</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-eg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Egypt</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-03-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Bailey Cntl Co Div Babcock</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>22</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$68,222.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Talia Riopelle</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1126</td><td style="width: 200px;"><span class="p-column-title">Name</span>Talia Riopelle</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gt" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Guatemala</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-02-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ford Brothers Wholesale Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>69</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$29,164.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Van Shire</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1127</td><td style="width: 200px;"><span class="p-column-title">Name</span>Van Shire</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-05-12</td><td style="width: 200px;"><span class="p-column-title">Company</span>Cambridge Inn</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>4</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$61,651.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lucina Lary</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1128</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lucina Lary</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ch" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Switzerland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-11-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>Matricciani, Albert J Jr</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>11</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$79,938.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Bok Isaacs</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1129</td><td style="width: 200px;"><span class="p-column-title">Name</span>Bok Isaacs</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Chile</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-11-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Nelson Hawaiian Ltd</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>41</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$44,037.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Rolande Spickerman</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1130</td><td style="width: 200px;"><span class="p-column-title">Name</span>Rolande Spickerman</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Panama</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-07-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Neland Travel Agency</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>84</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$89,918.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Howard Paulas</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1131</td><td style="width: 200px;"><span class="p-column-title">Name</span>Howard Paulas</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-id" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Indonesia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-07-17</td><td style="width: 200px;"><span class="p-column-title">Company</span>Asendorf, J Alan Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>22</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$32,372.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Kimbery Madarang</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1132</td><td style="width: 200px;"><span class="p-column-title">Name</span>Kimbery Madarang</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Senegal</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-08-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Silberman, Arthur L Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>63</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$46,478.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Thurman Manno</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1133</td><td style="width: 200px;"><span class="p-column-title">Name</span>Thurman Manno</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-co" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Colombia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-05-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Honey Bee Breeding Genetics &amp;</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>47</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$30,674.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Becky Mirafuentes</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1134</td><td style="width: 200px;"><span class="p-column-title">Name</span>Becky Mirafuentes</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-rs" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Serbia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Wells Kravitz Schnitzer</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>62</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$47,714.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Beatriz Corrington</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1135</td><td style="width: 200px;"><span class="p-column-title">Name</span>Beatriz Corrington</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-za" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">South Africa</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-01-04</td><td style="width: 200px;"><span class="p-column-title">Company</span>Prohab Rehabilitation Servs</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>55</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$14,307.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Marti Maybury</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1136</td><td style="width: 200px;"><span class="p-column-title">Name</span>Marti Maybury</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-th" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Thailand</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Eldridge, Kristin K Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>3</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$82,069.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Nieves Gotter</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1137</td><td style="width: 200px;"><span class="p-column-title">Name</span>Nieves Gotter</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-lv" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Latvia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-12</td><td style="width: 200px;"><span class="p-column-title">Company</span>Vlahos, John J Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>3</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$11,182.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Leatha Hagele</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1138</td><td style="width: 200px;"><span class="p-column-title">Name</span>Leatha Hagele</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ua" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ukraine</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-03-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ninas Indian Grs &amp; Videos</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>67</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$17,126.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Valentin Klimek</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1139</td><td style="width: 200px;"><span class="p-column-title">Name</span>Valentin Klimek</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ci" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivory Coast</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-08-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Schmid, Gayanne K Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>14</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$19,724.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Melissa Wiklund</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1140</td><td style="width: 200px;"><span class="p-column-title">Name</span>Melissa Wiklund</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-jp" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Japan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-03-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>Moapa Valley Federal Credit Un</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>8</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$91,888.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Sheridan Zane</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1141</td><td style="width: 200px;"><span class="p-column-title">Name</span>Sheridan Zane</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-hr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Croatia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-15</td><td style="width: 200px;"><span class="p-column-title">Company</span>Kentucky Tennessee Clay Co</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>17</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$15,016.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Bulah Padilla</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1142</td><td style="width: 200px;"><span class="p-column-title">Name</span>Bulah Padilla</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ph" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Philippines</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Admiral Party Rentals &amp; Sales</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>58</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$23,118.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Audra Kohnert</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1143</td><td style="width: 200px;"><span class="p-column-title">Name</span>Audra Kohnert</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-nl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Netherlands</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-07-16</td><td style="width: 200px;"><span class="p-column-title">Company</span>Nelson, Karolyn King Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>82</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$90,560.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Daren Weirather</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1144</td><td style="width: 200px;"><span class="p-column-title">Name</span>Daren Weirather</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-il" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Israel</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-07-23</td><td style="width: 200px;"><span class="p-column-title">Company</span>Panasystems</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>96</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$34,155.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Fernanda Jillson</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1145</td><td style="width: 200px;"><span class="p-column-title">Name</span>Fernanda Jillson</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-mx" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Mexico</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-07-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Shank, Edward L Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>92</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$6,350.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Gearldine Gellinger</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1146</td><td style="width: 200px;"><span class="p-column-title">Name</span>Gearldine Gellinger</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-eg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Egypt</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-08-17</td><td style="width: 200px;"><span class="p-column-title">Company</span>Megibow &amp; Edwards</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>18</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$77,641.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Chau Kitzman</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1147</td><td style="width: 200px;"><span class="p-column-title">Name</span>Chau Kitzman</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-py" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Paraguay</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-07-04</td><td style="width: 200px;"><span class="p-column-title">Company</span>Benoff, Edward Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>9</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$43,289.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Theola Frey</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1148</td><td style="width: 200px;"><span class="p-column-title">Name</span>Theola Frey</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-vn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Vietnam</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-03-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Woodbridge Free Public Library</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>44</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$85,657.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Cheryl Haroldson</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1149</td><td style="width: 200px;"><span class="p-column-title">Name</span>Cheryl Haroldson</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">France</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-04-03</td><td style="width: 200px;"><span class="p-column-title">Company</span>New York Life John Thune</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>55</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$82,733.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Laticia Merced</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1150</td><td style="width: 200px;"><span class="p-column-title">Name</span>Laticia Merced</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-bf" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Burkina Faso</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-03-04</td><td style="width: 200px;"><span class="p-column-title">Company</span>Alinabal Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>21</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$38,004.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Carissa Batman</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1151</td><td style="width: 200px;"><span class="p-column-title">Name</span>Carissa Batman</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Greece</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-05-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Poletto, Kim David Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>91</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$29,038.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lezlie Craghead</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1152</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lezlie Craghead</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pa" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Panama</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-05-28</td><td style="width: 200px;"><span class="p-column-title">Company</span>Chang, Carolyn Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>30</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$75,123.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Ozell Shealy</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1153</td><td style="width: 200px;"><span class="p-column-title">Name</span>Ozell Shealy</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pk" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Pakistan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-08-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Silver Bros Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>14</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$33,214.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Arminda Parvis</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1154</td><td style="width: 200px;"><span class="p-column-title">Name</span>Arminda Parvis</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-id" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Indonesia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-02-09</td><td style="width: 200px;"><span class="p-column-title">Company</span>Newtec Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>77</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$80,651.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Reita Leto</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1155</td><td style="width: 200px;"><span class="p-column-title">Name</span>Reita Leto</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-be" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belgium</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-04-03</td><td style="width: 200px;"><span class="p-column-title">Company</span>Creative Business Systems</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>58</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$5,085.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Yolando Luczki</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1156</td><td style="width: 200px;"><span class="p-column-title">Name</span>Yolando Luczki</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">France</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-01-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Dal Tile Corporation</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>78</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$93,021.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lizette Stem</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1157</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lizette Stem</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sk" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Slovakia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-08-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Edward S Katz</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>67</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$37,287.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Gregoria Pawlowicz</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1158</td><td style="width: 200px;"><span class="p-column-title">Name</span>Gregoria Pawlowicz</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-eg" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Egypt</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-02-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>Oh My Goodknits Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>29</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$73,070.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Carin Deleo</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1159</td><td style="width: 200px;"><span class="p-column-title">Name</span>Carin Deleo</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">China</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-05-28</td><td style="width: 200px;"><span class="p-column-title">Company</span>Redeker, Debbie</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>13</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$64,422.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Chantell Maynerich</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1160</td><td style="width: 200px;"><span class="p-column-title">Name</span>Chantell Maynerich</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ee" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Estonia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-09-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Desert Sands Motel</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>75</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$36,826.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Dierdre Yum</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1161</td><td style="width: 200px;"><span class="p-column-title">Name</span>Dierdre Yum</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cz" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Czech Republic</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-12-20</td><td style="width: 200px;"><span class="p-column-title">Company</span>Cummins Southern Plains Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>1</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$93,101.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Larae Gudroe</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1162</td><td style="width: 200px;"><span class="p-column-title">Name</span>Larae Gudroe</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-si" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Slovenia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-11-28</td><td style="width: 200px;"><span class="p-column-title">Company</span>Lehigh Furn Divsn Lehigh</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>13</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$60,177.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Latrice Tolfree</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1163</td><td style="width: 200px;"><span class="p-column-title">Name</span>Latrice Tolfree</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-jm" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Jamaica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-11-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>United Van Lines Agent</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>73</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$47,198.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Kerry Theodorov</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1164</td><td style="width: 200px;"><span class="p-column-title">Name</span>Kerry Theodorov</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ro" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Romania</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-11-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Capitol Reporters</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>76</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$71,305.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Dorthy Hidvegi</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1165</td><td style="width: 200px;"><span class="p-column-title">Name</span>Dorthy Hidvegi</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Poland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-08-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Kwik Kopy Printing</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>60</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$17,526.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Fannie Lungren</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1166</td><td style="width: 200px;"><span class="p-column-title">Name</span>Fannie Lungren</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-by" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belarus</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-07-06</td><td style="width: 200px;"><span class="p-column-title">Company</span>Centro Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>24</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$16,596.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Evangelina Radde</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1167</td><td style="width: 200px;"><span class="p-column-title">Name</span>Evangelina Radde</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ci" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivory Coast</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-02-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Campbell, Jan Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>93</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$56,870.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Novella Degroot</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1168</td><td style="width: 200px;"><span class="p-column-title">Name</span>Novella Degroot</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-si" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Slovenia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-12-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Evans, C Kelly Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>30</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$82,928.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Clay Hoa</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1169</td><td style="width: 200px;"><span class="p-column-title">Name</span>Clay Hoa</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-py" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Paraguay</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-22</td><td style="width: 200px;"><span class="p-column-title">Company</span>Scat Enterprises</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>93</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Amy Elsner" src="demo/images/avatar/amyelsner.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Amy Elsner</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$64,181.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Jennifer Fallick</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1170</td><td style="width: 200px;"><span class="p-column-title">Name</span>Jennifer Fallick</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-au" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Australia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-12-24</td><td style="width: 200px;"><span class="p-column-title">Company</span>Nagle, Daniel J Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>88</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$30,561.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Irma Wolfgramm</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1171</td><td style="width: 200px;"><span class="p-column-title">Name</span>Irma Wolfgramm</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-be" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Belgium</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-10-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Serendiquity Bed &amp; Breakfast</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>70</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$24,617.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Eun Coody</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1172</td><td style="width: 200px;"><span class="p-column-title">Name</span>Eun Coody</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-tw" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Taiwan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-02-12</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ray Carolyne Realty</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>61</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$77,860.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Sylvia Cousey</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1173</td><td style="width: 200px;"><span class="p-column-title">Name</span>Sylvia Cousey</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ie" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ireland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-06-10</td><td style="width: 200px;"><span class="p-column-title">Company</span>Berg, Charles E</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>91</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$25,664.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Nana Wrinkles</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1174</td><td style="width: 200px;"><span class="p-column-title">Name</span>Nana Wrinkles</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-at" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Austria</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-04-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ray, Milbern D</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>98</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$98,113.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Layla Springe</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1175</td><td style="width: 200px;"><span class="p-column-title">Name</span>Layla Springe</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-za" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">South Africa</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-07-27</td><td style="width: 200px;"><span class="p-column-title">Company</span>Chadds Ford Winery</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>97</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ioni Bowcher" src="demo/images/avatar/ionibowcher.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ioni Bowcher</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$14,763.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Joesph Degonia</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1176</td><td style="width: 200px;"><span class="p-column-title">Name</span>Joesph Degonia</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-rs" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Serbia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-04-23</td><td style="width: 200px;"><span class="p-column-title">Company</span>A R Packaging</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>56</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$31,317.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Annabelle Boord</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1177</td><td style="width: 200px;"><span class="p-column-title">Name</span>Annabelle Boord</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gt" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Guatemala</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-09-16</td><td style="width: 200px;"><span class="p-column-title">Company</span>Corn Popper</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>76</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$30,883.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Stephaine Vinning</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1178</td><td style="width: 200px;"><span class="p-column-title">Name</span>Stephaine Vinning</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-au" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Australia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-05-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Birite Foodservice Distr</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>43</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$93,785.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Nelida Sawchuk</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1179</td><td style="width: 200px;"><span class="p-column-title">Name</span>Nelida Sawchuk</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-za" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">South Africa</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-06-22</td><td style="width: 200px;"><span class="p-column-title">Company</span>Anchorage Museum Of Hist &amp; Art</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>58</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$68,380.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Marguerita Hiatt</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1180</td><td style="width: 200px;"><span class="p-column-title">Name</span>Marguerita Hiatt</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gb" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">United Kingdom</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-10-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Haber, George D Md</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>72</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$93,454.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Carmela Cookey</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1181</td><td style="width: 200px;"><span class="p-column-title">Name</span>Carmela Cookey</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">France</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-07-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Royal Pontiac Olds Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>24</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$30,570.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Junita Brideau</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1182</td><td style="width: 200px;"><span class="p-column-title">Name</span>Junita Brideau</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-id" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Indonesia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-03-15</td><td style="width: 200px;"><span class="p-column-title">Company</span>Leonards Antiques Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>86</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Anna Fali" src="demo/images/avatar/annafali.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Anna Fali</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$79,506.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Claribel Varriano</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1183</td><td style="width: 200px;"><span class="p-column-title">Name</span>Claribel Varriano</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ec" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ecuador</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-04-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Meca</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>15</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$8,654.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Benton Skursky</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1184</td><td style="width: 200px;"><span class="p-column-title">Name</span>Benton Skursky</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-is" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Iceland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-02-19</td><td style="width: 200px;"><span class="p-column-title">Company</span>Nercon Engineering &amp; Mfg Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>9</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$13,368.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Hillary Skulski</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1185</td><td style="width: 200px;"><span class="p-column-title">Name</span>Hillary Skulski</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">France</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-03-25</td><td style="width: 200px;"><span class="p-column-title">Company</span>Replica I</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>82</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$92,631.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Merilyn Bayless</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1186</td><td style="width: 200px;"><span class="p-column-title">Name</span>Merilyn Bayless</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-jm" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Jamaica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2020-10-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>20 20 Printing Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>13</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$4,989.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Teri Ennaco</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1187</td><td style="width: 200px;"><span class="p-column-title">Name</span>Teri Ennaco</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pk" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Pakistan</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-12-21</td><td style="width: 200px;"><span class="p-column-title">Company</span>Publishers Group West</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>57</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Bernardo Dominic" src="demo/images/avatar/bernardodominic.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Bernardo Dominic</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$77,668.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Merlyn Lawler</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1188</td><td style="width: 200px;"><span class="p-column-title">Name</span>Merlyn Lawler</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-de" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Germany</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-26</td><td style="width: 200px;"><span class="p-column-title">Company</span>Nischwitz, Jeffrey L Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>45</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$3,525.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Georgene Montezuma</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1189</td><td style="width: 200px;"><span class="p-column-title">Name</span>Georgene Montezuma</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-sn" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Senegal</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-10-11</td><td style="width: 200px;"><span class="p-column-title">Company</span>Payne Blades &amp; Wellborn Pa</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-new">new</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>64</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$45,838.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Jettie Mconnell</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1190</td><td style="width: 200px;"><span class="p-column-title">Name</span>Jettie Mconnell</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-dk" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Denmark</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-10-18</td><td style="width: 200px;"><span class="p-column-title">Company</span>Coldwell Bnkr Wright Real Est</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>74</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$49,148.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Lemuel Latzke</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1191</td><td style="width: 200px;"><span class="p-column-title">Name</span>Lemuel Latzke</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-co" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Colombia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-02-13</td><td style="width: 200px;"><span class="p-column-title">Company</span>Computer Repair Service</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>79</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$96,709.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Melodie Knipp</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1192</td><td style="width: 200px;"><span class="p-column-title">Name</span>Melodie Knipp</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-fi" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Finland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2018-03-08</td><td style="width: 200px;"><span class="p-column-title">Company</span>Fleetwood Building Block Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>19</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$23,253.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Candida Corbley</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1193</td><td style="width: 200px;"><span class="p-column-title">Name</span>Candida Corbley</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-pl" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Poland</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2017-12-02</td><td style="width: 200px;"><span class="p-column-title">Company</span>Colts Neck Medical Assocs Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-negotiation">negotiation</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>11</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Onyama Limba" src="demo/images/avatar/onyamalimba.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Onyama Limba</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$40,836.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Karan Karpin</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1194</td><td style="width: 200px;"><span class="p-column-title">Name</span>Karan Karpin</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ee" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Estonia</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-01-07</td><td style="width: 200px;"><span class="p-column-title">Company</span>New England Taxidermy</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-proposal">proposal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>4</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Stephen Shaw" src="demo/images/avatar/stephenshaw.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Stephen Shaw</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$60,719.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Andra Scheyer</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1195</td><td style="width: 200px;"><span class="p-column-title">Name</span>Andra Scheyer</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ro" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Romania</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-08-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Ludcke, George O Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>62</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$17,419.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Felicidad Poullion</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1196</td><td style="width: 200px;"><span class="p-column-title">Name</span>Felicidad Poullion</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-gr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Greece</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2016-03-05</td><td style="width: 200px;"><span class="p-column-title">Company</span>Mccorkle, Tom S Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-renewal">renewal</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>64</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Elwin Sharvill" src="demo/images/avatar/elwinsharvill.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Elwin Sharvill</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$94,052.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Belen Strassner</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1197</td><td style="width: 200px;"><span class="p-column-title">Name</span>Belen Strassner</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ci" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivory Coast</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-12-14</td><td style="width: 200px;"><span class="p-column-title">Company</span>Eagle Software Inc</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>91</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Xuxue Feng" src="demo/images/avatar/xuxuefeng.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Xuxue Feng</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$54,241.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Gracia Melnyk</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1198</td><td style="width: 200px;"><span class="p-column-title">Name</span>Gracia Melnyk</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cr" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Costa Rica</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2019-06-01</td><td style="width: 200px;"><span class="p-column-title">Company</span>Juvenile &amp; Adult Super</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-unqualified">unqualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>40</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Asiya Javayant" src="demo/images/avatar/asiyajavayant.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Asiya Javayant</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$87,668.00</span></td></tr>
            <tr><td style="width: 150px; left: 0px;" class="p-frozen-column"><span class="p-column-title">Name</span>Jolanda Hanafan</td><td style="width: 100px; left: 150px;" class="p-frozen-column"><span class="p-column-title">Id</span>1199</td><td style="width: 200px;"><span class="p-column-title">Name</span>Jolanda Hanafan</td><td style="width: 200px;"><span class="p-column-title">Country</span><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-cm" width="30"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Cameroon</span></td><td style="width: 200px;"><span class="p-column-title">Date</span>2015-12-09</td><td style="width: 200px;"><span class="p-column-title">Company</span>Perez, Joseph J Esq</td><td style="width: 200px;"><span class="p-column-title">Status</span><span class="customer-badge status-qualified">qualified</span></td><td style="width: 200px;"><span class="p-column-title">Activity</span>27</td><td style="width: 200px;"><span class="p-column-title">Representative</span><img alt="Ivan Magalhaes" src="demo/images/avatar/ivanmagalhaes.png" style="vertical-align: middle;" width="32"><span style="margin-left: 0.5em; vertical-align: middle;" class="image-text">Ivan Magalhaes</span></td><td style="width: 150px; right: 0px;" class="p-frozen-column"><span class="p-column-title">Balance</span><span class="text-bold">$99,417.00</span></td></tr>
          </tbody>
        </table>
      </div>
      
      <div class="p-column-resizer-helper" style="display: none;"></div>
    </div> -->
  </div>
</template>

<script>
// const KsFinTh = {
//   props: ['column', 'renderTitle'],
//   render (h) {
//     if (this.renderTitle) {
//       return this.renderTitle(h, this.column.title)
//     } else {
//       return h('span', {}, [this.column.title])
//     }
//   }
// }

// const KsFinTd = {
//   props: ['column', 'row', 'items', 'index', 'rindex', 'expanded'],
//   render (h) {
//     const multi = this.column.multi
//     const multiDef = multi !== undefined
//     let field = ''
//     if (this.column.prop) {
//       field = this.row[this.column.prop]
//       if (multi !== undefined) {
//         field = field[multi]
//       }
//     }
//     if (this.column.scopedSlot) {
//       field = this.column.scopedSlot({ column: this.column, row: this.row, items: this.items, field: field, rindex: this.rindex, expanded: this.expanded })
//     }
//     const data = {
//       class: { multi: multiDef, right: this.column.right },
//       on: { click: () => { this.$emit('select-col', this.index) } }
//     }
//     return h('td', data, [field])
//   }
// }

export default {
  props: {

    //   data: {
    //     type: Array,
    //     required: true
    //   },

    //   sort: {
    //     type: String,
    //     default: ''
    //   },

    //   total: {
    //     type: Array,
    //     default: function () { return [] }
    //   },

    //   sticky: {
    //     type: Boolean,
    //     default: false
    //   },

  //   expanded: {
  //     type: Number,
  //   }
  },

  // components: { KsFinTd, KsFinTh },

  data () {
    return {
      //     sortField: '',
      //     sortDirect: null,
      //     columnsOptions: [],
      //     pressed: false,
      //     observer: null
    }
  },

  watch: {
  //   sort: {
  //     handler: 'initSort',
  //     immediate: true
  //   },

    //   'data.length' (val) {
    //     // console.log('Z1', val)

    //     if (val && this.infinite && !this.observer) {
    //       const options = {
    //         root: null,
    //         rootMargin: '0px',
    //         threshold: 0
    //       }
    //       this.observer = new IntersectionObserver(entries => {
    //         // if (this.data.length && entries[0].isIntersecting) {
    //         if (entries[0].isIntersecting) {
    //           this.$emit('infinite')
    //         }
    //       }, options);
    //       this.observer.observe(this.$refs.infinite)
    //     }

  //   }
  },

  computed: {
  //   canSelectRow () {
  //     return !!this.$listeners['select-row']
  //   },

  //   infinite () {
  //     return !!this.$listeners['infinite']
  //   }
  },

  created () {
  //   this.initOptions()
  },

  beforeDestroy() {
  //   if (this.observer) {
  //     this.observer.unobserve(this.$refs.infinite)
  //     this.observer = null;
  //   }
  },

  methods: {
  //   selectColHandler (colIndex, row) {
  //     if (this.canSelectRow) {
  //       this.$emit('select-row', row, colIndex)
  //     }
  //   },

    //   initSort (val) {
    //     if (val) {
    //       if (val.slice(0, 1) === '-') {
    //         this.sortDirect = false
    //         this.sortField = val.slice(1)
    //       } else {
    //         this.sortDirect = true
    //         this.sortField = val
    //       }
    //     } else {
    //       this.sortField = ''
    //       this.sortDirect = null
    //     }
    //   },

    //   mkStyle (width) {
    //     if (width) {
    //       return { width: width, minWidth: width, maxWidth: width }
    //     }
    //     return {}
    //   },

    //   initOptions () {
    //     this.columnsOptions = []
    //     this.$slots.default.forEach(item => {
    //       if (item.componentOptions) {
    //         if (item.componentOptions.tag === 'ks-fin-table-column') {
    //           let { prop, title, width, sorted, renderTitle, right } = item.componentOptions.propsData
    //           this.columnsOptions.push({
    //             prop,
    //             title,
    //             right: right !== undefined,
    //             sorted: sorted !== undefined,
    //             style: this.mkStyle(width),
    //             renderTitle: renderTitle,
    //             scopedSlot: (item.data.scopedSlots || {}).default
    //           })
    //         } else if (item.componentOptions.tag === 'ks-fin-table-column-multi') {
    //           let { prop, width, titles, renderTitle, right } = item.componentOptions.propsData
    //           titles.forEach((title, index) => {
    //             this.columnsOptions.push({
    //               prop,
    //               title,
    //               right: right !== undefined,
    //               style: this.mkStyle(width),
    //               renderTitle: renderTitle,
    //               multi: index,
    //               scopedSlot: (item.data.scopedSlots || {}).default
    //             })
    //           })
    //         }
    //       }
    //     })
    //   },

    //   thHandler (column) {
    //     if (column.sorted) {
    //       if (column.prop === this.sortField) {
    //         if (this.sortDirect === true) {
    //           this.sortDirect = false
    //         } else if (this.sortDirect === false) {
    //           this.sortDirect = null
    //           this.sortField = ''
    //         }
    //       } else {
    //         this.sortField = column.prop
    //         this.sortDirect = true
    //       }

    //       if (this.sortDirect == null && !this.sortField) {
    //         this.$emit('change-sort')
    //       } else {
    //         this.$emit('change-sort', (this.sortDirect ? '' : '-') + this.sortField)
    //       }
    //     }
    //   },

    //   iconComponent (column) {
    //     if (this.sortField === column.prop) {
    //       return this.sortDirect ? 'menu-down' : 'menu-up'
    //     } else {
    //       return 'menu-swap'
    //     }
    //   },

    //   onMouseDown (e) {
    //     this.pressed = true
    //     e.preventDefault()
    //     this.$el.addEventListener('mousemove', this.onMouseMove)
    //     this.$el.addEventListener('mouseup', this.onMouseUp)
    //     this.$el.addEventListener('mouseout', this.onMouseOut)
    //   },

    //   onMouseOut (e) {
    //     if (e.relatedTarget === this.$el) {
    //       this.onMouseUp(e)
    //     }
    //   },

    //   onMouseUp (e) {
    //     this.pressed = false
    //     e.preventDefault()
    //     this.$el.removeEventListener('mousemove', this.onMouseMove)
    //     this.$el.removeEventListener('mouseup', this.onMouseUp)
    //     this.$el.removeEventListener('mouseout', this.onMouseOut)
    //   },

  //   onMouseMove (e) {
  //     if (this.pressed) {
  //       e.preventDefault()
  //       this.$el.scrollLeft -= e.movementX
  //     }
  //   }
  }
}
</script>

<style lang="less">
@import "../../style.less";

// td {
//   z-index: 0;
// }

// .rcc-table {
//   background: #ffffff;
//   position: relative;
// }
// .rcc-table thead > tr > th {
// 	text-align: left;
// 	padding: 0.75rem 0.75rem;
// 	border: 1px solid #e4e4e4;
// 	border-width: 0 0 1px 0;
// 	font-weight: 500;
// 	color: rgba(0, 0, 0, 0.87);
// 	background: #ffffff;
// 	transition: none;
// }
// .rcc-table tbody > tr {
// 	background: #ffffff;
// 	color: rgba(0, 0, 0, 0.87);
// 	transition: none;
// }

// .rcc-table table {
//     border-collapse: collapse;
//     min-width: 100%;
//     table-layout: fixed;
// }
// .rcc-table .p-sortable-column {
//     cursor: pointer;
//     user-select: none;
// }
// .rcc-table .p-sortable-column .p-column-title,
// .rcc-table .p-sortable-column .p-sortable-column-icon,
// .rcc-table .p-sortable-column .p-sortable-column-badge {
//     vertical-align: middle;
// }
// .rcc-table .p-sortable-column .p-sortable-column-badge {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
// }
// .rcc-table-responsive-scroll > .rcc-table-wrapper {
//     overflow-x: auto;
// }
// .rcc-table-responsive-scroll > .rcc-table-wrapper > table,
// .rcc-table-auto-layout > .rcc-table-wrapper > table {
//     table-layout: auto;
// }
// .rcc-table-hoverable-rows .p-selectable-row {
//     cursor: pointer;
// }

// /* Scrollable */
// .rcc-table-scrollable .rcc-table-wrapper {
//     position: relative;
//     overflow: auto;
// }
// .rcc-table-scrollable thead,
// .rcc-table-scrollable tbody,
// .rcc-table-scrollable .rcc-table-tfoot {
//     display: block;
// }
// .rcc-table-scrollable thead > tr,
// .rcc-table-scrollable tbody > tr,
// .rcc-table-scrollable .rcc-table-tfoot > tr {
//     display: flex;
//     flex-wrap: nowrap;
//     width: 100%;
// }
// .rcc-table-scrollable thead > tr > th,
// .rcc-table-scrollable tbody > tr > td,
// .rcc-table-scrollable .rcc-table-tfoot > tr > td {
//     display: flex;
//     flex: 1 1 0;
//     align-items: center;
// }
// .rcc-table-scrollable thead {
//     position: sticky;
//     top: 0;
//     z-index: 1;
// }
// .rcc-table-scrollable .rcc-table-frozen-tbody {
//     position: sticky;
//     z-index: 1;
// }
// .rcc-table-scrollable .rcc-table-tfoot {
//     position: sticky;
//     bottom: 0;
//     z-index: 1;
// }
// .rcc-table-scrollable .p-frozen-column {
//     position: sticky;
//     background: inherit;
//     // background: red;
//     z-index: 1;
// }
// .rcc-table-scrollable th.p-frozen-column {
//     // z-index: 1;
//     z-index: 2;
// }
// .rcc-table-scrollable-both thead > tr > th,
// .rcc-table-scrollable-both tbody > tr > td,
// .rcc-table-scrollable-both .rcc-table-tfoot > tr > td,
// .rcc-table-scrollable-horizontal thead > tr > th
// .rcc-table-scrollable-horizontal tbody > tr > td,
// .rcc-table-scrollable-horizontal .rcc-table-tfoot > tr > td {
//     flex: 0 0 auto;
// }
// .rcc-table-flex-scrollable {
//     display: flex;
//     flex-direction: column;
//     height: 100%;
// }
// .rcc-table-flex-scrollable .rcc-table-wrapper {
//     display: flex;
//     flex-direction: column;
//     flex: 1;
//     height: 100%;
// }
// .rcc-table-scrollable .p-rowgroup-header {
//     position: sticky;
//     z-index: 1;
// }
// .rcc-table-scrollable.rcc-table-grouped-header thead,
// .rcc-table-scrollable.rcc-table-grouped-footer .rcc-table-tfoot {
//     display: table;
//     border-collapse: collapse;
//     width: 100%;
//     table-layout: fixed;
// }
// .rcc-table-scrollable.rcc-table-grouped-header thead > tr,
// .rcc-table-scrollable.rcc-table-grouped-footer .rcc-table-tfoot > tr {
//     display: table-row;
// }
// .rcc-table-scrollable.rcc-table-grouped-header thead > tr > th,
// .rcc-table-scrollable.rcc-table-grouped-footer .rcc-table-tfoot > tr > td {
//     display: table-cell;
// }

// /* Resizable */
// .rcc-table-resizable > .rcc-table-wrapper {
//     overflow-x: auto;
// }
// .rcc-table-resizable thead > tr > th,
// .rcc-table-resizable .rcc-table-tfoot > tr > td,
// .rcc-table-resizable tbody > tr > td {
//     overflow: hidden;
//     white-space: nowrap;
// }
// .rcc-table-resizable .p-resizable-column {
//     background-clip: padding-box;
//     position: relative;
// }
// .rcc-table-resizable-fit .p-resizable-column:last-child .p-column-resizer {
//     display: none;
// }
// .rcc-table .p-column-resizer {
//     display: block;
//     position: absolute !important;
//     top: 0;
//     right: 0;
//     margin: 0;
//     width: .5rem;
//     height: 100%;
//     padding: 0px;
//     cursor:col-resize;
//     border: 1px solid transparent;
// }
// .rcc-table .p-column-header-content {
//     display: flex;
//     align-items: center;
// }
// .rcc-table .p-column-resizer-helper {
//     width: 1px;
//     position: absolute;
//     z-index: 10;
//     display: none;
// }
// .rcc-table .p-row-editor-init,
// .rcc-table .p-row-editor-save,
// .rcc-table .p-row-editor-cancel {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     overflow: hidden;
//     position: relative;
// }

// /* Expand */
// .rcc-table .p-row-toggler {
//     display: inline-flex;
//     align-items: center;
//     justify-content: center;
//     overflow: hidden;
//     position: relative;
// }

// /* Reorder */
// .rcc-table-reorder-indicator-up,
// .rcc-table-reorder-indicator-down {
//     position: absolute;
//     display: none;
// }

// /* Loader */
// .rcc-table .rcc-table-loading-overlay {
//     position: absolute;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 2;
// }

// /* Filter */
// .p-column-filter-row {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }
// .p-column-filter-menu {
//     display: inline-flex;
//     margin-left: auto;
// }
// .p-column-filter-row .p-column-filter-element {
//     flex: 1 1 auto;
//     width: 1%;
// }
// .p-column-filter-menu-button,
// .p-column-filter-clear-button {
//     display: inline-flex;
//     justify-content: center;
//     align-items: center;
//     cursor: pointer;
//     text-decoration: none;
//     overflow: hidden;
//     position: relative;
// }
// .p-column-filter-overlay {
//     position: absolute;
//     top: 0;
//     left: 0;
// }
// .p-column-filter-row-items {
//     margin: 0;
//     padding: 0;
//     list-style: none;
// }
// .p-column-filter-row-item {
//     cursor: pointer;
// }
// .p-column-filter-add-button,
// .p-column-filter-remove-button {
//     justify-content: center;
// }
// .p-column-filter-add-button .p-button-label,
// .p-column-filter-remove-button .p-button-label {
//     flex-grow: 0;
// }
// .p-column-filter-buttonbar {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }
// .p-column-filter-buttonbar .p-button:not(.p-button-icon-only) {
//     width: auto;
// }

// /* Responsive */
// .rcc-table tbody > tr > td > .p-column-title {
//     display: none;
// }

// /* VirtualScroller */
// .rcc-table .p-virtualscroller-loading {
//     transform: none !important;
//     min-height: 0;
//     position: sticky;
//     top: 0;
//     left: 0;
// }

// .rcc-table thead > tr > th > div {
//   z-index: 10;
// }
</style>
