export default api => {
  return {
    allList: params => {
      return api.get('store/list', params)
        .then(({ data }) => data)
    },

    list: params => {
      return api.get('stores', params)
        .then(({ data }) => data)
    },

    get: id => {
      return api.get(`store/${id}`)
        .then(({ data }) => data)
    },

    create: params => {
      return api.post('store/0', params)
        .then(({ data }) => data)
    },

    update: (id, params) => {
      return api.post(`store/${id}`, params)
        .then(({ data }) => data)
    }
  }
}
