<template>
  <div
    v-if="$route.name === 'returns' && changes > 0"
    class="rcc-refund-changes"
  >
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'RccRefundChanges',

  computed: {
    changes() {
      return this.$store.refundChanges
    },

    text() {
      return `Изменений: ${this.changes}`
    }
  }
}
</script>

<style lang="scss" scoped>
.rcc-refund-changes {
  color: $error
}
</style>
