<script>
export default {
  props: {
    head: {
      type: String
    }
  }
}
</script>

<template>
  <div class="rcc-panel">
		<h5 v-if="head">{{head}}</h5>
    <slot />
  </div>
</template>

<style lang="less">
.rcc-panel {
	background-color: #ffffff;
	padding: 1rem;
	margin-bottom: 2rem;
	box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
	border-radius: 5px;

  h5 {
    margin-bottom: 14px;
  }
}
</style>
