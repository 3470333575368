
export function getActiveSuppliersList() {
  return this.$api.get('supplier/list', { is_active: 1 })
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export function getAllSuppliersList() {
  return this.$api.get('supplier/list')
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getSuppliers(params) {
      return this.$api.get('suppliers', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getBlockedSuppliers(params) {
      return this.$api.get('suppliers/blocked', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createSupplier(params) {
      return this.$api.post('supplier/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getSupplier(id) {
      return this.$api.get('supplier/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    changeSupplierStatus(id, params) {
      return this.$api.post('supplier/' + id +  '/change_status', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateSupplier(id, params) {
      return this.$api.post('supplier/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}

export function suppliers(api) {
  return {
    allList: (params = {}) => {
      return api.get('supplier/list', params)
        .then(({ data }) => data)
        .catch(error => Promise.reject(error))
    },

    allActiveList: () => {
      return api.get('supplier/list', { is_active: 1 })
        .then(({ data: { items } }) => items)
        .catch(error => Promise.reject(error))
    },

    balances: params => {
      return api.get('/balances', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getExcel: params => {
      return api.get('balances/xls', params, { responseType: 'blob' })
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    changeWerks: (id, werks) => {
      return api.post(`supplier/${id}/werks`, { werk_ids: werks })
        .then(({ data }) => data)
    }
  }
}
