export default {
  name: 'rcc-table-column',
  props: {
    prop: {
      type: String
    },

    title: {
      type: String
    },

    sorted: {
      type: Boolean
    },

    width: {
      type: String
    },

    renderTitle: {
      type: Function
    },

    right: {
      type: Boolean,
      default: false
    }
  }
}
