<template>
  <svg
    class="rcc-icon"
    :class="{ spin }"
    :style="{ height: size + 'px', width: size + 'px'}"
    @mouseover="overHandler"
    @mouseleave="leaveHandler"
    @click="$emit('click')" v-if="icon">
    <use :xlink:href="`#${iconName}`" />
  </svg>
</template>

<script>
export default {
  name: 'RccIcon',

  props: {
    icon: {
      type: String
    },

    iconHover: {
      type: String
    },

    spin: {
      type: Boolean,
      default: false
    },

    size: {
      type: Number,
      default: 22
    }
  },

  data () {
    return {
      hover: false
    }
  },

  computed: {
    iconName () {
      return (this.hover && this.iconHover) ? this.iconHover : this.icon
    }
  },

  methods: {
    overHandler () {
      this.hover = true
    },

    leaveHandler () {
      this.hover = false
    }
  }
}
</script>

<style>
  .rcc-icon {
    fill: currentColor;
    /* height: 1em; */
    /* margin-bottom: 0.125em; */
    vertical-align: middle;
    /* width: 1em; */
  }

  .spin {
    animation: icon-spin 2s infinite linear;
  }

  @keyframes icon-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
</style>
