import Vue from 'vue'
import Router from 'vue-router'
import constants from '@/utils/constants'
import store from './store/index.js'

const ROLES = constants.users

const Login = () => import('@/views/Login.vue')

// Users
const UsersList = () => import('@/views/Users/UsersList.vue')
const UsersNew = () => import('@/views/Users/UsersNew.vue')
const UsersEdit = () => import('@/views/Users/UsersEdit.vue')

// Returns
const returnsTable = () => import('@/views/Returns/Returns.vue')
const returnsTableNew = () => import('@/views/Returns/ReturnsNew.vue')
const returnsTableEdit = () => import('@/views/Returns/ReturnsEdit.vue')

// Processes
const processLog = () => import('@/views/Processes/ProcessLog.vue')
const processList = () => import('@/views/Processes/ProcessList.vue')
const processNew = () => import('@/views/Processes/ProcessNew.vue')
const processEdit = () => import('@/views/Processes/ProcessEdit.vue')
// const startingProcesses = () => import('@/views/Processes/StartingProcesses.vue')

// Suppliers
const suppliersList = () => import('@/views/Suppliers/SuppliersList.vue')
const suppliersNew = () => import('@/views/Suppliers/SuppliersNew.vue')
const suppliersEdit = () => import('@/views/Suppliers/SuppliersEdit.vue')

const bookingChanges = () => import('@/views/BookingChanges/BookingChanges.vue')

// Suppliers balance
const suppliersBalance = () => import('@/views/SuppliersBalance/SuppliersBalance.vue')

// Regions
const regionsList = () => import('@/views/Regions/RegionsList.vue')
const regionsNew = () => import('@/views/Regions/RegionsNew.vue')
const regionsEdit = () => import('@/views/Regions/RegionsEdit.vue')

// Return points
const returnPointsList = () => import('@/views/ReturnPoints/ReturnPointsList.vue')
const returnPointsNew = () => import('@/views/ReturnPoints/ReturnPointsNew.vue')
const returnPointsEdit = () => import('@/views/ReturnPoints/ReturnPointsEdit.vue')

// TradingNetworks
const tradingNetworksList = () => import('@/views/TradingNetworks/TradingNetworksList.vue')
const tradingNetworksNew = () => import('@/views/TradingNetworks/TradingNetworksNew.vue')
const tradingNetworksEdit = () => import('@/views/TradingNetworks/TradingNetworksEdit.vue')

// Legal entities
const legalEntitiesList = () => import('@/views/LegalEntities/LegalEntitiesList.vue')
const legalEntitiesNew = () => import('@/views/LegalEntities/LegalEntitiesNew.vue')
const legalEntitiesEdit = () => import('@/views/LegalEntities/LegalEntitiesEdit.vue')

// Rcc stores
const rccStoresList = () => import('@/views/RccStores/list.vue')
const rccStoresNew = () => import('@/views/RccStores/new.vue')
const rccStoresEdit = () => import('@/views/RccStores/edit.vue')

// Pc stores
const PcStoresList = () => import('@/views/PcStores/list.vue')
const PcStoresNew = () => import('@/views/PcStores/new.vue')
const PcStoresEdit = () => import('@/views/PcStores/edit.vue')

// Return statuses
const returnStatusesList = () => import('@/views/ReturnStatuses/ReturnStatusesList.vue')
const returnStatusesNew = () => import('@/views/ReturnStatuses/ReturnStatusesNew.vue')
const returnStatusesEdit = () => import('@/views/ReturnStatuses/ReturnStatusesEdit.vue')

// Detailing
const detailingList = () => import('@/views/Detailing/DetailingList.vue')
const detailingNew = () => import('@/views/Detailing/DetailingNew.vue')
const detailingEdit = () => import('@/views/Detailing/DetailingEdit.vue')

// Analytics
// const analytics = () => import('@/views/Analytics/Analytics.vue')

// Notifications
const notifications = () => import('@/views/Notifications/Notifications.vue')

// User profile
const userProfile = () => import('@/views/UserProfile/UserProfile.vue')

// Districts
const districtsList = () => import('@/views/Districts/index.vue')
const districtsNew = () => import('@/views/Districts/new.vue')
const districtsEdit = () => import('@/views/Districts/edit.vue')

const DEF_REDIRECT = { name: 'returns' }

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: DEF_REDIRECT,
      meta: {}
    }, {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { layout: 'no-menu' }
    },

    // Users
    {
      path: '/users',
      name: 'users',
      component: UsersList,
      meta: { auth: true, sidebarItem: 'users', access: [ROLES.admin] }
    }, {
      path: '/users/new',
      name: 'users-new',
      component: UsersNew,
      meta: { auth: true, sidebarItem: 'users', access: [ROLES.admin] }
    }, {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: UsersEdit,
      meta: { auth: true, sidebarItem: 'users', access: [ROLES.admin], editAccess: [ROLES.admin] }
    },

    // Returns
    {
      path: '/returns',
      name: 'returns',
      component: returnsTable,
      meta: {
        auth: true,
        sidebarItem: 'returns',
        access: [ROLES.admin, ROLES.manager, ROLES.supplier, ROLES.user],
        editAccess: [ROLES.admin, ROLES.manager]
      }
    }, {
      path: '/returns/new',
      name: 'returns-new',
      component: returnsTableNew,
      meta: {
        auth: true,
        sidebarItem: 'returns',
        access: [ROLES.admin, ROLES.manager],
        editAccess: [ROLES.admin, ROLES.manager]
      }
    }, {
      path: '/returns/edit/:id',
      name: 'returns-edit',
      component: returnsTableEdit,
      meta: {
        auth: true,
        sidebarItem: 'returns',
        access: [ROLES.admin, ROLES.manager],
        editAccess: [ROLES.admin, ROLES.manager]
      }
    },

    // Processes
    {
      path: '/process-log',
      name: 'process-log',
      component: processLog,
      meta: {
        auth: true,
        sidebarItem: 'process-log',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/processes',
      name: 'processes',
      component: processList,
      meta: { auth: true, sidebarItem: 'processes', access: [ROLES.admin, ROLES.manager], editAccess: [ROLES.admin] }
    }, {
      path: '/process/new',
      name: 'process-new',
      component: processNew,
      meta: { auth: true, sidebarItem: 'processes', access: [ROLES.admin], editAccess: [ROLES.admin] }
    }, {
      path: '/process/edit/:id',
      name: 'process-edit',
      component: processEdit,
      meta: { auth: true, sidebarItem: 'processes', access: [ROLES.admin], editAccess: [ROLES.admin] }
    },
    // {
    //   path: '/starting-processes',
    //   name: 'starting-processes',
    //   component: startingProcesses,
    //   meta: { auth: true, sidebarItem: 'starting-processes', access: [ROLES.admin], editAccess: [ROLES.admin] }
    // },

    // Suppliers
    {
      path: '/suppliers',
      name: 'suppliers',
      component: suppliersList,
      meta: {
        auth: true, sidebarItem: 'suppliers',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin, ROLES.manager],
        createAccess: [ROLES.admin]
      }
    }, {
      path: '/suppliers/new',
      name: 'suppliers-new',
      component: suppliersNew,
      meta: {
        auth: true,
        sidebarItem: 'suppliers',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/suppliers/edit/:id',
      name: 'suppliers-edit',
      component: suppliersEdit,
      meta: {
        auth: true,
        sidebarItem: 'suppliers',
        access: [ROLES.admin, ROLES.manager],
        editAccess: [ROLES.admin]
      }
    },

    //Booking changes
    {
      path: '/booking-changes',
      name: 'booking-changes',
      component: bookingChanges,
      meta: {
        auth: true,
        sidebarItem: 'booking-changes',
        access: [ROLES.admin, ROLES.manager],
        editAccess: [ROLES.admin]
      }
    },

    // Suppliers balance
    {
      path: '/suppliers-balance',
      name: 'suppliers-balance',
      component: suppliersBalance,
      meta: {
        auth: true,
        sidebarItem: 'suppliers-balance',
        access: [ROLES.admin, ROLES.manager, ROLES.supplier],
        editAccess: [ROLES.admin, ROLES.manager]
      }
    },

    // Regions
    {
      path: '/regions',
      name: 'regions',
      component: regionsList,
      meta: {
        auth: true,
        sidebarItem: 'regions',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/regions/new',
      name: 'regions-new',
      component: regionsNew,
      meta: {
        auth: true,
        sidebarItem: 'regions',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/regions/edit/:id',
      name: 'regions-edit',
      component: regionsEdit,
      meta: {
        auth: true,
        sidebarItem: 'regions',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Return Points
    {
      path: '/return-points',
      name: 'return-points',
      component: returnPointsList,
      meta: {
        auth: true,
        sidebarItem: 'return-points',
        access: [ROLES.admin, ROLES.manager, ROLES.supplier, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/return-points/new',
      name: 'return-points-new',
      component: returnPointsNew,
      meta: {
        auth: true,
        sidebarItem: 'return-points',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/return-points/edit/:id',
      name: 'return-points-edit',
      component: returnPointsEdit,
      meta: {
        auth: true,
        sidebarItem: 'return-points',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Trading networks
    {
      path: '/trading-networks',
      name: 'trading-networks',
      component: tradingNetworksList,
      meta: {
        auth: true,
        sidebarItem: 'trading-networks',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/trading-networks/new',
      name: 'trading-networks-new',
      component: tradingNetworksNew,
      meta: {
        auth: true,
        sidebarItem: 'trading-networks',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/trading-networks/edit/:id',
      name: 'trading-networks-edit',
      component: tradingNetworksEdit,
      meta: {
        auth: true,
        sidebarItem: 'trading-networks',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Legal entities
    {
      path: '/legal-entities',
      name: 'legal-entities',
      component: legalEntitiesList,
      meta: {
        auth: true,
        sidebarItem: 'legal-entities',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/legal-entities/new',
      name: 'legal-entities-new',
      component: legalEntitiesNew,
      meta: {
        auth: true,
        sidebarItem: 'legal-entities',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/legal-entities/edit/:id',
      name: 'legal-entities-edit',
      component: legalEntitiesEdit,
      meta: {
        auth: true,
        sidebarItem: 'legal-entities',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Rcc stores
    {
      path: '/rcc-stores',
      name: 'rcc-stores',
      component: rccStoresList,
      meta: {
        auth: true,
        sidebarItem: 'rcc-stores',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/rcc-stores/new',
      name: 'rcc-stores-new',
      component: rccStoresNew,
      meta: {
        auth: true,
        sidebarItem: 'rcc-stores',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/rcc-stores/edit/:id',
      name: 'rcc-stores-edit',
      component: rccStoresEdit,
      meta: {
        auth: true,
        sidebarItem: 'rcc-stores',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Pc stores
    {
      path: '/pc-stores',
      name: 'pc-stores',
      component: PcStoresList,
      meta: {
        auth: true,
        sidebarItem: 'pc-stores',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/pc-stores/new',
      name: 'pc-stores-new',
      component: PcStoresNew,
      meta: {
        auth: true,
        sidebarItem: 'pc-stores',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/pc-stores/edit/:id',
      name: 'pc-stores-edit',
      component: PcStoresEdit,
      meta: {
        auth: true,
        sidebarItem: 'pc-stores',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Return statuses
    {
      path: '/return-statuses',
      name: 'return-statuses',
      component: returnStatusesList,
      meta: {
        auth: true,
        sidebarItem: 'return-statuses',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/return-statuses/new',
      name: 'return-statuses-new',
      component: returnStatusesNew,
      meta: {
        auth: true,
        sidebarItem: 'return-statuses',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/return-statuses/edit/:id',
      name: 'return-statuses-edit',
      component: returnStatusesEdit,
      meta: {
        auth: true,
        sidebarItem: 'return-statuses',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Detailing
    {
      path: '/detailing',
      name: 'detailing',
      component: detailingList,
      meta: {
        auth: true,
        sidebarItem: 'detailing',
        access: [ROLES.admin, ROLES.manager, ROLES.user],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/detailing/new',
      name: 'detailing-new',
      component: detailingNew,
      meta: {
        auth: true,
        sidebarItem: 'detailing',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    }, {
      path: '/detailing/edit/:id',
      name: 'detailing-edit',
      component: detailingEdit,
      meta: {
        auth: true,
        sidebarItem: 'detailing',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    // Analytics
    // {
    //   path: '/analytics',
    //   name: 'analytics',
    //   component: analytics,
    //   meta: {
    //     auth: true,
    //     sidebarItem: 'analytics',
    //     access: [ROLES.supplier],
    //     editAccess: []
    //   }
    // },

    // // Notifications
    // {
    //   path: '/notifications',
    //   name: 'notifications',
    //   component: notifications,
    //   meta: {
    //     auth: true,
    //     sidebarItem: 'notifications',
    //     access: [ROLES.admin],
    //     editAccess: [ROLES.admin]
    //   }
    // },

    // User profile
    {
      path: '/user-profile',
      name: 'user-profile',
      component: userProfile,
      meta: {
        auth: true
      }
    },

    // Districts
    {
      path: '/districts',
      name: 'districts',
      component: districtsList,
      meta: {
        auth: true,
        sidebarItem: 'districts',
        access: [ROLES.admin, ROLES.manager],
        editAccess: [ROLES.admin]
      }
    },
    {
      path: '/districts/new',
      name: 'districts-new',
      component: districtsNew,
      meta: {
        auth: true,
        sidebarItem: 'districts',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },
    {
      path: '/districts/edit/:id',
      name: 'districts-edit',
      component: districtsEdit,
      meta: {
        auth: true,
        sidebarItem: 'districts',
        access: [ROLES.admin],
        editAccess: [ROLES.admin]
      }
    },

    {
      path: '*',
      redirect: DEF_REDIRECT
    }
  ]
})

router.beforeEach((to, from, next) => {
  const currentUser = store.user.id
  const requireAuth = to.matched.some(route => route.meta.auth)

  if(requireAuth && currentUser === null) {
    store.user.refresh()
      .then(() => {
        next(checkAccess(to))
      })
  }
  else {
    next(checkAccess(to))
  }
})

function checkAccess(to) {
  const role = store.user.role

  return to.matched.some(route => !route.meta.access || route.meta.access.includes(role)) || { path: '/' }
}

export default router
