
const STATUSES_GROUPS = {
  agreed: 'AGREED',
  processed: 'PROCESSED',
  others: 'OTHERS'
}

export default {
  roles: {
    1: 'Администратор',
    2: 'Менеджер',
    3: 'Пользователь',
    4: 'Поставщик'
  },

  users: {
    admin: 1,
    manager: 2,
    user: 3,
    supplier: 4
  },

  status: {
    active: 'Активный',
    blocked: 'Заблокирован'
  },

  contract: {
    ransom: 'Выкуп',
    lease: 'Аренда',
    uot: 'УОТ'
  },

  bookingType: {
    intermediate: 'Промежуточное бронирование',
    consolidation: 'Консолидационное бронирование'
  },

  weekDays: {
    1: 'Пн',
    2: 'Вт',
    3: 'Ср',
    4: 'Чт',
    5: 'Пт',
    6: 'Сб',
    7: 'Вс'
  },

  rowsOnTable: 50,

  hasReturnsChanges: 1,

  cancelStatuses: {
    IN_PROGRESS: 'IN_PROGRESS',
    AVAILABLE: 'AVAILABLE'
  },

  statusesGroups: STATUSES_GROUPS,

  statusesGroupsNames: {
    [STATUSES_GROUPS.agreed] : 'Согласуемые',
    [STATUSES_GROUPS.processed] : 'Обработанные',
    [STATUSES_GROUPS.others]: 'Иные'
  },

  networkIds: {
    x5: 1,
    tander: 4
  },

  returnStatusesIds: {
    exported: 2
  }
}
