import Vue from 'vue'
import  RccDialog from './rcc-dialog.vue'

Vue.component('rcc-dialog', RccDialog)

/*
this.$message('err.response.data.message')

this.$confirm('Удалить механика?').then(
  confirm => { console.log('YYYYYYYYYY') },
  cancel => { console.log('NNNNNNNNNNNN') }
)
*/

export default {
  install: function (Vue) {
    Vue.prototype.$confirm = text => {
      return new Promise((resolve, reject) => {
        const RccConfirmComponent = Vue.extend(RccDialog)
        // eslint-disable-next-line no-new
        new RccConfirmComponent({
          el: document.createElement('div'),
          propsData: { text, resolve, reject, auto: true }
        })
      })
    }

    Vue.prototype.$alert = text => {
      return new Promise(resolve => {
        const RccAlertComponent = Vue.extend(RccDialog)
        // eslint-disable-next-line no-new
        new RccAlertComponent({
          el: document.createElement('div'),
          propsData: { text, resolve, auto: true }
        })
      })
    }

    Vue.prototype.$message = text => {
      const RccMessageComponent = Vue.extend(RccDialog)
      // eslint-disable-next-line no-new
      new RccMessageComponent({
        el: document.createElement('div'),
        propsData: { text, auto: true }
      })
    }
  }
}
