<template>
  <snackbar ref="snackbar" :holdTime="5000" position="top-right"/>
</template>

<script>
import Snackbar from 'vuejs-snackbar'

export default {
  name: 'rcc-snackbar',

  components: { Snackbar },

  created() {
    window.events.$on(
      'snackbar', (message, type) => this.show(message, type)
    )
  },

  methods: {
    show(message, type) {
      this.$refs.snackbar[type](message)
    }
  }
}
</script>
