import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticClass:"rcc-header",attrs:{"app":"","flat":"","color":"white"}},[_c(VAppBarNavIcon,{on:{"click":function($event){return _vm.$emit('clickToDrawerIcon')}}}),_c('div',{staticClass:"rcc-header__info-block"},[_c(VBreadcrumbs,{attrs:{"items":_vm.$store.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c(VIcon,[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('rcc-refund-changes')],1),_c(VSpacer),(!_vm.isSupplierRole)?_c(VMenu,{staticClass:"rcc-header__notifications-menu",attrs:{"transition":"scroll-y-transition","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"notifications",class:{'has-notifications': _vm.notifications.length > 0}},[_c(VIcon,[_vm._v("mdi-bell")])],1)])]}}],null,false,463835385),model:{value:(_vm.isShowNotifications),callback:function ($$v) {_vm.isShowNotifications=$$v},expression:"isShowNotifications"}},[(_vm.notifications.length > 0)?_c('div',{staticClass:"rcc-header__notifications"},[_c('button',{staticClass:"rcc-header__close-all-notifications",on:{"click":_vm.handleCloseAllNotificationsClick}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-bell-remove-outline")]),_c('span',[_vm._v("Закрыть все")])],1),_c(VList,{staticClass:"rcc-header__notifications-list"},[_c(VListItemGroup,{staticClass:"rcc-header__list-group"},_vm._l((_vm.notifications),function(item,index){return _c('rcc-notification',{key:index,staticClass:"rcc-header__notification-item",attrs:{"message":item.message},on:{"close":function($event){return _vm.removeNotification(item.id)}}})}),1)],1)],1):_c('div',{staticClass:"rcc-header__no-notifications"},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-bell-off-outline")]),_c('span',[_vm._v("Нет новых уведомлений")])],1)]):_vm._e(),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push({path: '/', name: 'user-profile'})}}},[_c(VIcon,[_vm._v("mdi-account")])],1),_c('rcc-dialog-button',{attrs:{"icon":"mdi-logout","title":"Точно хотите выйти?","confirm-text":"Да"},on:{"confirm":_vm.handleLogOutDialogConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }